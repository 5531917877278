import { Input } from 'antd';

export default function PasswordField(props: {
  placeholder: string;
  name: string;
  handleChange: (value: string, name: string) => void;
  inputValue?: string;
}) {

  function handleChangeInput (event: React.ChangeEvent<HTMLInputElement>) {
    props.handleChange(event.target.value, props.name);
  }

  return (
    <Input.Password
      placeholder={props.placeholder}
      name={props.name}
      onChange={handleChangeInput}
      value={props.inputValue}
    >
    </Input.Password>
  );
}
