import { createContext, useContext } from "react";
import { Updater } from "use-immer";
import { NotifMessage } from "../pages/Notifications";

export interface Tokens {
  AccessToken: string;
  RefreshToken: string;
  IdToken: string;
  userType: string;
}

export interface TokenData {
  AccessToken?: string;
  RefreshToken?: string;
  IdToken?: string;
  userType?: string;
}
export const getTokens: () => Tokens = () => {
  return {
    AccessToken: localStorage.getItem("AccessToken") || "",
    RefreshToken: localStorage.getItem("RefreshToken") || "",
    IdToken: localStorage.getItem("IdToken") || "",
    userType: localStorage.getItem("userType") || "",
  };
};

export const storeTokens = (data: {
  tokens: TokenData;
  userType?: string;
}): Tokens => {
  const { tokens, userType } = data;
  const currentTokens = getTokens();
  const updatedTokens = {
    AccessToken: tokens.AccessToken || currentTokens.AccessToken,
    RefreshToken: tokens.RefreshToken || currentTokens.RefreshToken,
    IdToken: tokens.IdToken || currentTokens.IdToken,
    userType: userType || currentTokens.userType,
  };
  localStorage.setItem("AccessToken", updatedTokens.AccessToken);
  localStorage.setItem("IdToken", updatedTokens.IdToken);
  localStorage.setItem("RefreshToken", updatedTokens.RefreshToken);
  localStorage.setItem("userType", updatedTokens.userType);
  return updatedTokens;
};

export const removeTokens = () => {
  localStorage.removeItem("AccessToken");
  localStorage.removeItem("RefreshToken");
  localStorage.removeItem("IdToken");
  localStorage.removeItem("userType");
};

export interface UserState {
  userType: string;
  userDetails: UserDetails;
  loggedIn: boolean;
  notifications: NotifMessage[];
  isLoading: boolean;
}

export interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: string;
  city: string;
  address: string;
  companies: Company[];
}

export interface Company {
  id: string;
  created_at: Date;
  name: string;
  registration_code: string;
  country: string;
  city: string;
  phone_number: string;
  company_email: string;
  address: string;
  bank_account: string;
  vat_number: string;
  representative_name: string;
  representative_surname: string;
  representative_email: string;
  representative_phone_number: string;
}

export interface AppState {
  userState: UserState;
  setUserState: Updater<UserState>;
}

export const initialUserDetails = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  country: "",
  city: "",
  address: "",
  companies: [],
};

export const initialUserState = {
  userType: "",
  userDetails: initialUserDetails,
  loggedIn: false,
  notifications: [],
  isLoading: false
};

export const UserContext = createContext<AppState>({
  userState: initialUserState,
  setUserState: (draft) => {},
});

export const AuctionFormDataContext = createContext({
  repatriationFormData: {},
});

export default UserContext;
