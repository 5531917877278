import MainNavigation from "../ui/MainNavigation";
import React, { useContext, useMemo, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Drawer,
  Dropdown, FloatButton,
  Layout as AntLayout,
  Menu,
  MenuProps,
  Row, Tag,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import {
  BellOutlined,
  LogoutOutlined, QuestionCircleOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import UserContext from "../../context/AppContext";
import NorthSentinelLogoWhite from "../../images/NorthSentinelLogoWhite.png";
import Notifications, { NotifMessage } from "../../pages/Notifications";

export default function Layout(props: {
  children:
    | string
    | number
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal;
}) {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const { Header, Footer, Sider, Content } = AntLayout;
  const { Title, Text } = Typography;
  const { userState } = useContext(UserContext);

  const contentStyle: React.CSSProperties = {
    backgroundColor: "white",
    height: "auto",
    padding: "32px",
    maxWidth: "1280px",
  };

  const menuItems = [
    {
      label: <Link to={"login"}>Login</Link>,
      key: "login",
    },
    {
      label: <Link to={"registration"}>Register</Link>,
      key: "registration",
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <>
          <Text>
            {userState.userDetails.firstName} {userState.userDetails.lastName}
          </Text>
        </>
      ),
      key: "nameSurname",
      type: "group",
    },
    {
      type: "divider",
    },
    {
      label: <Link to={"profile"}>Profile</Link>,
      key: "profile",
      icon: <UserOutlined />,
    },
    {
      label: <Link to={"profile/change_password"}>Change Password</Link>,
      key: "reset",
      icon: <UnlockOutlined />,
    },
    {
      type: "divider",
    },
    {
      label: <Link to={"logout"}>Logout</Link>,
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const userContext = useContext(UserContext);
  const notifications = userContext.userState.notifications;
  const unreadNotifications = useMemo(() => {
    return (
      notifications.filter(
        (notification: NotifMessage) => !notification.read_at
      ) || []
    );
  }, [notifications]);

  function notificationBadge() {
    let counter: number = unreadNotifications.length;
    return counter;
  }

  return (
    <AntLayout hasSider>
      {userState.loggedIn && (
        <Sider
          style={{
            height: "100vh",
          }}
        >
          <Title
            level={5}
            style={{
              color: "white",
              textAlign: "center",
            }}
          >
            NORTH
          </Title>
          <Title
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            <img
              src={NorthSentinelLogoWhite}
              width="30%"
              height="30%"
              alt="North Sentinel"
            />
          </Title>
          <Title
            level={5}
            style={{
              color: "white",
              textAlign: "center",
              margin: 0,
              marginBottom: "32px",
            }}
          >
            SENTINEL
          </Title>
          <MainNavigation />
        </Sider>
      )}
      <AntLayout
        style={{
          background: "white",
        }}
      >
        <Header
          style={{
            background: "white",
            padding: "0",
          }}
        >
          <Row>
            <Col
              xs={24}
              xl={12}
              style={{
                borderBottom: "1px solid #f1f1f1",
              }}
            >
              {!userState.loggedIn ? (
                <Menu
                  mode="horizontal"
                  items={menuItems}
                  style={{
                    padding: "0 16px",
                    border: "none",
                  }}
                />
              ) : (
                <>
                  <span
                    style={{
                      margin: "20px",
                    }}
                  >
                    You are logged in as:
                    <Tag
                        color="#304155"
                        style={{
                          textTransform: "capitalize",
                          marginLeft: "12px",
                        }}

                    >
                      {userState.userType}
                    </Tag>
                  </span>
                </>
              )}
            </Col>
            <Col
              xs={24}
              xl={12}
              style={{
                textAlign: "right",
                paddingRight: "32px",
                borderBottom: "1px solid #e1e1e1",
              }}
            >
              {userState.loggedIn && (
                <>
                  <Badge
                    size={"small"}
                    count={notificationBadge()}
                    style={{
                      marginRight: "34px",
                      marginTop: "4px",
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: "#ffffff",
                        fontSize: "25px",
                        marginRight: "15px",
                        color: "#304155",
                        cursor: "pointer",
                      }}
                      onClick={showDrawer}
                      icon={<BellOutlined />}
                    />
                  </Badge>

                  <Drawer
                    title={
                      <Button
                        type="link"
                        icon={<BellOutlined />}
                        style={{
                          color: "#304155",
                          fontSize: "16px",
                          cursor: "text",
                        }}
                      >
                        Notifications
                      </Button>
                    }
                    placement="right"
                    onClose={onClose}
                    open={open}
                  >
                    <Notifications />
                  </Drawer>
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <a onClick={(e) => e.preventDefault()}>
                      <Avatar
                        size={36}
                        style={{
                          backgroundColor: "#f56a00",
                        }}
                        icon={<UserOutlined />}
                      />
                    </a>
                  </Dropdown>
                </>
              )}
            </Col>
          </Row>
        </Header>

        <Content style={contentStyle}>
          {props.children}

          <FloatButton.Group shape="circle" style={{ right: 24 }}>
            {userState.loggedIn && (
              <FloatButton
                  icon={<QuestionCircleOutlined />}
                  tooltip={<div>Help</div>}
                  onClick={() => window.open("https://docs.sentinel.lt")}
              />
            )}
            <FloatButton />
            <FloatButton.BackTop visibilityHeight={0} />
          </FloatButton.Group>

        </Content>

        <Footer
          style={{
            background: "white",
          }}
        >
          <Text>&#169; 2023 North Sentinel. All rights reserved.</Text>
        </Footer>
      </AntLayout>
    </AntLayout>
  );
}
