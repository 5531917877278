// TODO fix "two children with the same key, ``"

export const RauterPathItems: { [key: string]: string } = {
  about: "/about",
  account: "",
  auctions: "/auctions",
  createauction: "/createauction",
  empty: "empty",
  home: "/",
  login: "/login",
  myauctions: "/myauctions",
  registration: "/registration",
  reset: "/reset",
  viewauction: "/viewauction/:auctionId",
  notifications: "/notifications",
  profile: "/profile",
};

export default RauterPathItems;
