import { Breadcrumb, Button, Col, Form, message, Row, Typography } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { serviceUrl } from "../../../AppConfig";
import UserContext, {
  getTokens,
  storeTokens,
} from "../../../context/AppContext";
import TextAreaField from "./formElements/TextAreaField";
import TextField from "./formElements/TextField";

const { Title, Text } = Typography;

export default function ProfileForm() {
  const [editedUserInfo, setEditedUserInfo] = useImmer({});
  const userInformation = useContext(UserContext).userState.userDetails;
  const { userState, setUserState } = useContext(UserContext);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const navigate = useNavigate();

  function handleChange(value: string | number, name: string) {
    setEditedUserInfo((prevEditedUserInfo) => ({
      ...prevEditedUserInfo,
      [name]: value,
    }));
  }

  function initiateUserInfoEdit() {

    setUserState((userStateDraft) => {
      userStateDraft.isLoading = true;
    });
    messageApi.open({
      key,
      type: "loading",
      content: "Saving...",
      duration: 10,
    });
    const targetUrl = serviceUrl + "edit_profile/";
    const config = {
      headers: { Authorization: "Bearer " + getTokens().AccessToken },
    };
    const payload = {
      editedUserInfo: { ...editedUserInfo },
      email: userInformation.email,
      refreshToken: getTokens().RefreshToken,
    };
    axios
      .post(targetUrl, payload, config)
      .then(function (response: AxiosResponse) {
        setUserState((userStateDraft) => {
          userStateDraft.userDetails.country = response.data.country;
          userStateDraft.userDetails.city = response.data.city;
          userStateDraft.userDetails.address = response.data.address;
          userStateDraft.isLoading = false;
        });
        // unpack token data
        const data = {
          tokens: {
            ...response.data.tokens,
          },
        };
        // store tokens and userType to local storage
        storeTokens(data);
        messageApi.open({
          key,
          type: "success",
          content: "Saved",
          duration: 1,
          onClose() {
            navigate("/profile");
          },
        });
      })
      .catch(function (error: AxiosError) {
        // handle error
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "error",
          content: "Error",
          duration: 4,
        });
        console.log("error", error);
      });
  }

  return (
    <>
      {contextHolder}
      <Title
        level={3}
        style={{
          marginBottom: "24px",
        }}
      >
        Edit profile
      </Title>

      <Breadcrumb
        style={{
          marginBottom: "40px",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
        <Link to="/profile">Profile</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="#">Edit</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col xs={24} xl={8}></Col>
        <Col xs={24} xl={8}>
          <Form
            layout="vertical"
            style={{ maxWidth: "420px", margin: "0 auto" }}
            initialValues={userInformation}
          >
            <Form.Item label="First name">
              <TextField
                placeholder={userInformation.firstName}
                defaultValue={userInformation.firstName}
                name={"firstName"}
                handleChange={handleChange}
              />
            </Form.Item>
            <Form.Item label={<Text>Last name</Text>}>
              <TextField
                placeholder={userInformation.lastName}
                defaultValue={userInformation.lastName}
                name={"lastName"}
                handleChange={handleChange}
              />
            </Form.Item>
            <Form.Item label={<Text>Phone number</Text>}>
              <TextField
                placeholder={userInformation.phoneNumber}
                defaultValue={userInformation.phoneNumber}
                name={"phoneNumber"}
                handleChange={handleChange}
              />
            </Form.Item>
            <Form.Item label={<Text>Country</Text>}>
              <TextField
                placeholder={userInformation.country}
                defaultValue={userInformation.country}
                name={"country"}
                handleChange={handleChange}
              />
            </Form.Item>
            <Form.Item label={<Text>City</Text>}>
              <TextField
                placeholder={userInformation.city}
                defaultValue={userInformation.city}
                name={"city"}
                handleChange={handleChange}
              />
            </Form.Item>
            <Form.Item label={<Text>Address</Text>}>
              <TextAreaField
                placeholder={userInformation.address}
                defaultValue={userInformation.address}
                name={"address"}
                handleChange={handleChange}
                rows={1}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={userState.isLoading} onClick={initiateUserInfoEdit}>
                Save
              </Button>
              <Button
                type="default"
                onClick={
                  () => navigate("/profile",
                      {
                              replace: true
                      }
                  )
                }
                style={{
                  marginLeft: "12px",
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} xl={8}></Col>
      </Row>
    </>
  );
}
