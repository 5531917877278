import { Button, DatePicker, Divider, Form, Input, InputNumber, message, Typography } from "antd";
import axios, { AxiosResponse, AxiosError } from "axios";
import { useContext, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { serviceUrl } from "../../../../AppConfig";
import UserContext, { getTokens } from "../../../../context/AppContext";
import {
  disabledPastDates,
  fieldIsRequired,
  FormDataTypes,
  InitialDataType,
} from "../../../../pages/CreateAuction";

const { Text } = Typography;

export default function ApplicantContactDetails(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const { userState, setUserState } = useContext(UserContext);
  
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  
  const navigate = useNavigate();

  const repType = props.filledData.auction_details.repatriationType;

  function handleSubmit() {
    messageApi.open({
      key,
      type: "loading",
      content: "Submitting",
      duration: 10,
    });
    setUserState((userStateDraft) => {
      userStateDraft.isLoading = true;
    });
    let targetUrl = serviceUrl + "create_auction/";
    axios
      .post(targetUrl, props.filledData, {
        headers: { Authorization: "Bearer " + getTokens().AccessToken },
      })
      .then(function (response: AxiosResponse) {
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "success",
          content: "Submitted",
          duration: 1,
          onClose() {
            navigate("/myauctions");
          },
        });
      })
      .catch(function (error: AxiosError) {
        const errrorResponse = error.response;
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "error",
          content: "Submission failed",
          duration: 4,
        });
        if (errrorResponse?.status === 401) {
          navigate("/login", { replace: true });
        } else if (errrorResponse?.status !== 401) {
        }
      });
  };

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    // props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      if (!(auctionData.applicantContacts)) {
        auctionData.applicantContacts = {}
      }
      draftFilledData.endingTime = values.endingTime;
      draftFilledData.bidIncrement = values.bidIncrement;
      auctionData.applicantContacts.name = values.name;
      auctionData.applicantContacts.email = values.email;
      auctionData.applicantContacts.phone = values.phoneNumber;
      auctionData.applicantContacts.companyName = values.companyName;
      auctionData.applicantContacts.specialRequirements = values.specialRequirements;
    })

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
    handleSubmit()
  }


  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <>
      {contextHolder}
      <Form
        name="patientMedicalDetails"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{
          endingTime: props.filledData.endingTime,
          bidIncrement: props.filledData.bidIncrement,
          name: data.applicantContacts?.name || userState.userDetails.firstName || "",
          email: data.applicantContacts?.email || userState.userDetails.email || "",
          phoneNumber: data.applicantContacts?.phone || userState.userDetails.phoneNumber || "",
          companyName: data.applicantContacts?.companyName || "",
          specialRequirements: data.applicantContacts?.specialRequirements || "",
        }}
      >
        <Text>Applicant contact information and application closing time </Text>
        <Divider />
        <Form.Item
          label={<Text>Name</Text>}
          name="name"
          rules={fieldIsRequired}
        >
          <Input
            allowClear
          // defaultValue={userState.userDetails.firstName}
          />
        </Form.Item>
        <Form.Item
          label={<Text>Email</Text>}
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid email!",
            },
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            allowClear
          // defaultValue={userState.userDetails.email}
          />
        </Form.Item>
        <Form.Item
          label={<Text>Phone number</Text>}
          name="phoneNumber"
          rules={[
            {
              validator: (_rule, value) => {
                if (/^\+\d+$/.test(value)) {
                  return Promise.resolve(value);
                } else {
                  return Promise.reject(
                    "Please enter a valid phone number, egz:. +37012345678"
                  );
                }
              },
            },
            {
              min: 9,
              message: "Please enter a valid phone number, egz:. +37012345678",
            },
            {
              required: true,
              message: "Please enter your phone number",
            },
          ]}
        >
          <Input
            allowClear
          // defaultValue={userState.userDetails.phoneNumber}
          />
        </Form.Item>
        <Form.Item
          label={<Text>Company</Text>}
          name="companyName"
        // rules={fieldIsRequired}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label={<Text>Special requirements</Text>}
          name="specialRequirements"
        // rules={fieldIsRequired}
        >
          <Input.TextArea rows={1} allowClear />
        </Form.Item>
        <Divider />
        <Form.Item
          label={<Text>Pick application closing date and time</Text>}
          name="endingTime"
          rules={fieldIsRequired}
        >
          {/* TODO Cant be earlyer then soonestDateOfTransport */}
          <DatePicker
            showNow={false}
            placeholder="Select date and time"
            showTime={{
              format: "HH:mm",
            }}
            onChange={(value) => {
              props.setFilledData((draftFilledData: any) => {
                draftFilledData.endingTime = value;
              })
            }}
            style={{ width: "100%" }}
            disabledDate={disabledPastDates}
            format={"YYYY-MM-DD HH:mm"}
          />
        </Form.Item>
        <Form.Item
          label={<Text>Specify smallest offer change</Text>}
          name="bidIncrement"
          rules={[
            {
              type: "number",
              message: "number",
            },
          ]}
        >
          <InputNumber
            min={1}
            // max={500}
            addonAfter={"€"}
            style={{ width: "100%" }}
            defaultValue={1}
          />
        </Form.Item>
        <div>
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Done
          </Button>
        </div>
      </Form>
    </>
  );
}
