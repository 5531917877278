import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Form, Button, Row, Col, Typography, message } from "antd";
import EmailField from "./formElements/EmailField";
import PasswordField from "./formElements/PasswordField";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError, AxiosResponse } from "axios";
import UserContext, {
  storeTokens,
  getTokens,
} from "../../../context/AppContext";
import SelectField from "./formElements/SelectField";
import { serviceUrl } from "../../../AppConfig";
import ForgotPasswordModal from "../modals/ForgotPassword";
import { userTypeOptions } from "../../commonOptions/userTypeOptions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import extractDataFromToken from "../../commonOptions/getDataFromToken";
import NorthSentinelLogoWhite from "../../../images/NorthSentinelLogoWhite.png";

const { Title } = Typography;
// TODO move login functionality to Login.tsx leave form elements only

export default function LoginForm() {
  const [loginData, setLoginData] = useState({
    userType: "",
    email: "",
    password: "",
    error: "",
  });

  const { userState, setUserState } = useContext(UserContext);

  const { Text } = Typography;

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  // loging on change
  useEffect(() => {
    console.log(loginData);
  }, [loginData]);

  let navigate = useNavigate();
  let ommitEmptySelected = true;

  function handleSelectChange(value: string, name: string) {
    setLoginData((prevLoginData) => ({
      ...prevLoginData,
      [name]: value,
    }));
  }

  function handleChange(value: any, name: string) {
    setLoginData((prevLoginData) => ({
      ...prevLoginData,
      [name]: value,
    }));
  }

  function updateChangeState(name: string, value: any) {
    return setLoginData((prevLoginData) => ({
      ...prevLoginData,
      [name]: value,
    }));
  }

  const [modalForgotPasswordShow, setModalForgotPasswordShow] = useState(false);
  const [typeSelectorShow, setTypeSelectorShow] = useState(false);

  useEffect(() => {
    setLoginData((prevLoginData) => ({
      ...prevLoginData,
      error: "",
    }));
  }, [modalForgotPasswordShow]);

  function addLoginData(_event: SyntheticEvent) {
    setUserState((userStateDraft) => {
      userStateDraft.isLoading = true;
    });
    messageApi.open({
      key,
      type: "loading",
      content: "Login - initiated",
      duration: 10,
    });
    const targetUrl = serviceUrl + "login/";
    updateChangeState("error", "");

    axios
      .post(targetUrl, loginData)
      .then(function (response: AxiosResponse) {
        // unpack token data
        if (response.data === "SelectUserType") {
          messageApi.open({
            key,
            type: "info",
            content: "Please select your user type",
            duration: 2,
          });
          setTypeSelectorShow(true);
          setUserState((userStateDraft) => {
            userStateDraft.isLoading = false;
          });
        } else {
          const data = {
            tokens: {
              ...response.data.tokens,
            },
            userType: response.data["user_type"],
          };
          // store tokens and userType to local storage
          storeTokens(data);
          const dataFromToken = extractDataFromToken(getTokens().IdToken);
          messageApi.open({
            key,
            type: "success",
            content: "Login - successful",
            duration: 1,
            onClose() {
              setUserState((userStateDraft) => {
                userStateDraft.loggedIn = true;
                userStateDraft.userDetails.firstName = dataFromToken.name;
                userStateDraft.userDetails.lastName = dataFromToken.family_name;
                userStateDraft.userDetails.email = dataFromToken.email;
                userStateDraft.userDetails.phoneNumber =
                  dataFromToken.phone_number;
                userStateDraft.userType = response.data["user_type"];
                userStateDraft.isLoading = false;
              });
              navigate("/", { replace: true });
            },
          });
        }
      })
      .catch(function (error: AxiosError) {
        // handle error
        setUserState((userStateDraft: any) => {
          userStateDraft.loggedIn = false;
          userStateDraft.userType = "";
          userStateDraft.isLoading = false;
        });
        updateChangeState(
          "error",
          <>
            <ExclamationCircleOutlined /> Provided information is incorrect.
            Please try again.
          </>
        );
        messageApi.open({
          key,
          type: "error",
          content: "Login - error",
          duration: 2,
        });
      });
  }

  return (
    <>
      {contextHolder}
      <Row>
        <Col
          xs={24}
          xl={8}
          style={{
            background: "#041527",
            // background: "#304155",
            padding: "32px",
          }}
        >
          <Title
            level={2}
            style={{
              color: "white",
              textAlign: "center",
            }}
          >
            NORTH
          </Title>
          <Title
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            <img
              src={NorthSentinelLogoWhite}
              width="200px"
              height="159px"
              alt="North Sentinel"
            />
          </Title>
          <Title
            level={2}
            style={{
              color: "white",
              textAlign: "center",
              margin: 0,
              marginBottom: "32px",
            }}
          >
            SENTINEL
          </Title>
        </Col>
        <Col
          xs={24}
          xl={16}
          style={{
            padding: "32px 64px",
            height: "100vh",
            background: "#FFFFFF",
          }}
        >
          <Form
            style={{ maxWidth: "420px" }}
            onKeyUp={(event) =>
              event.key === "Enter" ? addLoginData(event) : ""
            }
          >
            <Title level={3}>Login</Title>
            {typeSelectorShow ? (
              <Form.Item>
                <SelectField
                  label="Select user type"
                  name="userType"
                  handleChange={handleSelectChange}
                  selecterLabel="Select user type"
                  inputValue={loginData.userType}
                  ommitEmptySelected={ommitEmptySelected}
                  options={userTypeOptions}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item>
                  <EmailField
                    placeholder="Email address"
                    name="email"
                    handleChange={handleChange}
                    inputValue={loginData.email}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  validateStatus={loginData.error !== "" ? "error" : ""}
                  help={loginData.error}
                >
                  <PasswordField
                    placeholder="Password"
                    name="password"
                    handleChange={handleChange}
                    inputValue={loginData.password}
                  />
                </Form.Item>
              </>
            )}
            <Button
              type="primary"
              loading={userState.isLoading}
              onClick={addLoginData}
            >
              Login
            </Button>
            {typeSelectorShow ? (
              <Button
                type="default"
                onClick={() => setTypeSelectorShow(false)}
                style={{
                  marginLeft: "12px",
                }}
              >
                Cancel
              </Button>
            ) : (
              <>
                <Text
                  onClick={() => setModalForgotPasswordShow(true)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "16px",
                  }}
                >
                  Forgot password?
                </Text>

                <ForgotPasswordModal
                  show={modalForgotPasswordShow}
                  onCloseButton={() => setModalForgotPasswordShow(false)}
                  selectedUserType={loginData.userType}
                  email={loginData.email}
                />
              </>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
}
