import { Button, DatePicker, Divider, Form, Input, InputNumber, Typography } from "antd";
import dayjs from "dayjs";
import {
  disabledFutureDates,
  FormDataTypes,
  InitialDataType,
} from "../../../../../pages/CreateAuction";

const { Text } = Typography;

export default function RemainsDetails(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      auctionData.countryOfResidence = values.countryOfResidence;
      auctionData.patientNameSurname = values.patientNameSurname;
      auctionData.dateOfBirth = values.dateOfBirth ? values.dateOfBirth : dayjs();
      auctionData.height = values.height;
      auctionData.weight = values.weight;
    });

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }

  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <>
      <Form
        name="patientMedicalDetails"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{
          countryOfResidence: data.countryOfResidence,
          patientNameSurname: data.patientNameSurname,
          dateOfBirth: data.dateOfBirth ? data.dateOfBirth : dayjs(),
          height: data.height,
          weight: data.weight,
        }}
      >
        <Text>Repatriated Person Details</Text>
        <Divider />
        <Form.Item
          label={<Text>Country of residence</Text>}
          name="countryOfResidence"
        // rules={fieldIsRequired}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label={<Text>Patient name, surname</Text>}
          name="patientNameSurname"
        //   rules={fieldIsRequired}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label={<Text>Patient date of birth</Text>}
          name="dateOfBirth"
        >
          <DatePicker
            style={{ width: "100%" }}
            defaultValue={dayjs()}
            format={"YYYY-MM-DD"}
            disabledDate={disabledFutureDates}
          />
        </Form.Item>
        <Form.Item
          label={<Text>Patient height</Text>}
          name="height"
          rules={[
            {
              type: "number",
            },
          ]}
        >
          <InputNumber
            min={1}
            addonAfter={"cm"}
            style={{ width: "100%" }}
          // onKeyDown={(event) => {
          //   if (!/^[0-9\b]+$/.test(event.key)) {
          //     event.preventDefault();
          //   }
          // }}
          />
        </Form.Item>
        <Form.Item
          label={<Text>Patient weight</Text>}
          name="weight"
          rules={[
            {
              type: "number",
            },
          ]}
        >
          <InputNumber
            min={1}
            addonAfter={"kg"}
            style={{ width: "100%" }}
          // onKeyDown={(event) => {
          //   if (!/^[0-9\b]+$/.test(event.key)) {
          //     event.preventDefault();
          //   }
          // }}
          />
        </Form.Item>
        <div>
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
