import axios, { AxiosResponse } from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { serviceUrl } from "../AppConfig";
import { Link, useNavigate } from "react-router-dom";
import UserContext, { getTokens, removeTokens, UserDetails } from "../context/AppContext";
import {Breadcrumb, Button, Skeleton, Space, Table, Typography} from "antd";
const { Title } = Typography;

export default function Profile() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { setUserState } = useContext(UserContext);
  const userInformation = useContext(UserContext).userState.userDetails;
  useEffect(() => {
    console.log("userDetais", userInformation);
  }, [userInformation]);
  useMemo(() => {
    setIsLoading(true);
    const config = {
      headers: { Authorization: "Bearer " + getTokens().AccessToken },
    };
    const targetUrl = serviceUrl + "user_profile/";
    axios
      .get<UserDetails>(targetUrl, config)
      .then((response: AxiosResponse<UserDetails>) => {
        setUserState((userStateDraft) => {
          userStateDraft.userDetails.country = response.data.country;
          userStateDraft.userDetails.city = response.data.city;
          userStateDraft.userDetails.address = response.data.address;
          userStateDraft.userDetails.companies = response.data.companies;
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        if (error.response?.status === 401) {
          removeTokens();
        }
        navigate("/login/", { replace: true });
      });
  }, []);
  const companies = userInformation.companies;

  const dataProfile = [
    {
      name: userInformation.firstName,
      surname: userInformation.lastName,
      email: userInformation.email,
      phone: userInformation.phoneNumber,
      country: userInformation.country,
      city: userInformation.city,
      address: userInformation.address,
    }
  ];

  const columnsProfile = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
      key: 'surname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
  ];

  return (
    <>
      <Title
          level={3}
          style={{
            marginBottom: '24px'
          }}
      >
        Profile
      </Title>

        <Breadcrumb
            style={{
                marginBottom: '40px'
            }}
        >
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link to="#">Profile</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
            
      {isLoading ? (
          <Skeleton active />
      ) : (
        <>
          <Table
              dataSource={dataProfile}
              columns={columnsProfile}
              pagination={{hideOnSinglePage: true}}
          />
          <Space>
            <Link
              to={"edit"}
            >
              <Button
                type="primary"
                style={{
                    marginTop: "32px"
                }}
              >
                Edit
              </Button>
            </Link>
            <Link 
              to={"change_password"}
            >
              <Button
                type="text"
                style={{
                  marginTop: "32px"
                }}
              >
                Change password
              </Button>
            </Link>
          </Space>

          {companies.length !== 0 && (
            <>
              <br />
              <br />
              <div>Companies:</div>
              {companies.map((company) => {
                return (
                  <ul>
                    <li>
                      <div>name - {company.name}</div>
                      <div>registration_code -{company.registration_code}</div>
                      <div>country - {company.country}</div>
                      <div>city - {company.city}</div>
                      <div>phone_number - {company.phone_number}</div>
                      <div>company_email - {company.company_email}</div>
                      <div>address - {company.address}</div>
                      <div>bank_account - {company.bank_account}</div>
                      <div>vat_number - {company.vat_number}</div>
                      <div>
                        representative_name - {company.representative_name}
                      </div>
                      <div>
                        {"representative_surname - "}
                        {company.representative_surname}
                      </div>
                      <div>
                        representative_email - {company.representative_email}
                      </div>
                      <div>
                        {"representative_phone_number - "}
                        {company.representative_phone_number}
                      </div>
                    </li>
                  </ul>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
}
