import { Button, DatePicker, Divider, Form, Input, InputNumber, Typography } from "antd";

import dayjs from "dayjs";
import {
  disabledFutureDates,
  FormDataTypes,
  InitialDataType,
} from "../../../../../pages/CreateAuction";

const { Text } = Typography;

export default function PatientGeneralInformation(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      auctionData.patientNameSurname = values.patientNameSurname;
      auctionData.dateOfBirth = values.dateOfBirth ? values.dateOfBirth : dayjs();
      auctionData.weight = values.weight;
      auctionData.height = values.height;
      auctionData.diagnosis = values.diagnosis;
      auctionData.dateOfAdmission = values.dateOfAdmission ? values.dateOfAdmission : dayjs();
      auctionData.generalStatus = values.generalStatus;
    });

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }
  
  const data = props.filledData.auction_details[repType] as FormDataTypes


return (
  <>
    <Form
      name="patientGeneralInformation"
      layout="vertical"
      style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
      onFinish={next}
      initialValues={{ 
        patientNameSurname: data.patientNameSurname,
        dateOfBirth: data.dateOfBirth,
        weight: data.weight,
        height: data.height,
        diagnosis: data.diagnosis,
        dateOfAdmission: data.dateOfAdmission,
        generalStatus: data.generalStatus,
       }}
    >
      <Text>Information about Patient (General information)</Text>
      <Divider />
      <Form.Item
        label={<Text>Patient name, surname</Text>}
        name="patientNameSurname"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        label={<Text>Patient date of birth</Text>}
        name="dateOfBirth"
      >
        <DatePicker
          style={{ width: "100%" }}
          defaultValue={dayjs()}
          format={"YYYY-MM-DD"}
          disabledDate={disabledFutureDates}
        />
      </Form.Item>
      <Form.Item
        label={<Text>Patient height</Text>}
        name="height"
        rules={[
          {
            type: "number",
          },
        ]}
      >
        <InputNumber min={1} addonAfter={"cm"} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label={<Text>Patient weight</Text>}
        name="weight"
        rules={[
          {
            type: "number",
          },
        ]}
      >
        <InputNumber
          min={1}
          addonAfter={"kg"}
          style={{ width: "100%" }}
        // onKeyDown={(event) => {
        //   console.log(event)
        //   if (!/^[0-9\b]+$/.test(event.key)) {
        //     event.preventDefault();
        //   }
        // }}
        />
      </Form.Item>
      <Form.Item
        label={<Text>Diagnosis</Text>}
        name="diagnosis"
      //   rules={fieldIsRequired}
      >
        <Input.TextArea rows={1} allowClear />
      </Form.Item>
      <Form.Item
        label={<Text>Date of addmision in the hospital</Text>}
        name="dateOfAdmission"
      >
        <DatePicker
          style={{ width: "100%" }}
          defaultValue={dayjs()}
          format={"YYYY-MM-DD"}
          disabledDate={disabledFutureDates}
        />
      </Form.Item>
      <Form.Item
        label={<Text>General status</Text>}
        name="generalStatus"
      //   rules={fieldIsRequired}
      >
        <Input.TextArea rows={1} allowClear />
      </Form.Item>

      <div>
        <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
          Previous
        </Button>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </div>
    </Form>
  </>
);
}
