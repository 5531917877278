import { Button, Space, Table, Skeleton, Tag } from "antd";
import { Updater } from "use-immer";
import Column from "antd/es/table/Column";
import { useNavigate } from "react-router-dom";
import { auctionItemData } from "../context/RepDataTypes";
import { showLocalizedDate } from "../context/handleDateFunctions";
import {ClockCircleOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

export default function AuctionsTable(props: {
  auctionItems: auctionItemData[];
  auctionUpdate: Updater<auctionItemData[]>;
  loadingStatus: boolean;
}) {
  const navigate = useNavigate();

  return (
    <>
      {props.loadingStatus ? (
        <Skeleton active />
      ) : (
        <Table
          loading={props.loadingStatus}
          dataSource={props.auctionItems}
          rowKey={(record) => record.id}
          pagination={{ pageSize: 10 }}
        >
          <Column
            title="No."
            dataIndex=""
            key="id"
            // width={50}
            render={(_value: number, record: auctionItemData, index) => {
              const rowNumber: number = index + 1;
              return rowNumber;
            }}
          />
          <Column title="Name" dataIndex="id" key="id" />
          <Column
            title="Due date"
            dataIndex="ending_time"
            key="ending_time"
            // width={150}
            render={(date: string) => showLocalizedDate(date)}
          />
          <Column
            title="Current bid"
            dataIndex="top_bid"
            key="top_bid"
            // width={50}
            render={(top_bid) => {
              return top_bid &&
                top_bid.amount !== null &&
                top_bid.amount !== undefined
                ? `${top_bid.amount} €`
                : "";
            }}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status) => {
              return status === "started" ? (
                <Tag color="#87d068"
                     icon={<ClockCircleOutlined />}
                     style={{
                       marginLeft: "24px",
                     }}>
                  ACTIVE
                </Tag>
              ) : (
                <Tag color="#f50"
                     icon={<ExclamationCircleOutlined />}
                     style={{
                       marginLeft: "24px",
                     }}>
                  INACTIVE
                </Tag>
              );
            }}
          />
          <Column
            title="Actions"
            key="actions"
            render={(_value: string, record: auctionItemData) => (
              <Space size="middle">
                <Button
                    type="link"
                  size="small"
                  onClick={() => {
                    navigate(`/viewauction/${record.id}`, {
                      state: { auctionItemData: record },
                    });
                  }}
                >
                  View
                </Button>
              </Space>
            )}
          />
        </Table>
      )}
    </>
  );
}
