import axios, { AxiosError, AxiosResponse } from "axios";
import { serviceUrl } from "../../AppConfig";
import { getTokens } from "../../context/AppContext";
import { RepTypeOption } from "../ui/form/formElements/SelectField";

export interface Option {
  key: string;
  label: string;
  value: string;
}

export default function getCommonOptions(selectionName: string) {
  const targetUrl = serviceUrl + "common_options/";
  const config = {
    headers: { Authorization: "Bearer " + getTokens().AccessToken },
    params: {
      commonOptionsName: selectionName,
    },
  };
  return axios
    .get(targetUrl, config)
    .then(function (response: AxiosResponse): Option[] {
      const commonOptions = response.data;
      console.log(commonOptions);
      return commonOptions;
    })
    .catch(function (error: AxiosError): Option[] {
      console.log(error.response?.status);
      return [{ key: "", label: "", value: "Loading?..." }];
    });
}

