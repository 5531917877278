import { Menu, MenuProps } from "antd";
import { useContext } from "react";
import UserContext from "../../context/AppContext";

import {
  RetweetOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { RauterPathItems } from "../commonOptions/getRouterPathOtions";

function MainNavigation() {
  const fromUserContext = useContext(UserContext);
  const userLoggedIn = fromUserContext.userState.loggedIn;
  const userTypeApplicant = fromUserContext.userState.userType === "applicant";
  const navigate = useNavigate();

  type MenuItem = Required<MenuProps>["items"][number];

  const onClick: MenuProps["onClick"] = (event) => {
    console.log(event.key);
    console.log(RauterPathItems);
    navigate(event.key);
  };
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuProps["items"] = [
    getItem("Dashboard", RauterPathItems.home,  <DashboardOutlined />),
    userLoggedIn
      ? getItem("Repatriations", RauterPathItems.empty, <RetweetOutlined />, [
          userTypeApplicant
            ? getItem("Create Auction", RauterPathItems.createauction)
            : null,
          userTypeApplicant
            ? getItem("My Auctions", RauterPathItems.myauctions)
            : null,
          !userTypeApplicant
            ? getItem("Auctions", RauterPathItems.auctions)
            : null,
        ])
      : null
  ];

  return (
    <Menu
      style={{
        border: "none",
      }}
      theme="dark"
      onClick={onClick}
      defaultOpenKeys={[RauterPathItems.home]}
      mode="inline"
      items={items}
    />
  );
}

export default MainNavigation;
