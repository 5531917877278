export interface IdTokenData {
  email: string;
  name: string;
  family_name: string;
  phone_number: string;
}

function fromBinary(binary: string): string {
  const bytes = Uint8Array.from({ length: binary.length }, (element, index) =>
    binary.charCodeAt(index)
  );
  const decoder = new TextDecoder("utf-8");

  return decoder.decode(bytes);
}

export default function extractDataFromToken(token: string): IdTokenData {
  if (token === "") {
    return { email: "", name: "", family_name: "", phone_number: "" };
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const decodeText = fromBinary(atob(base64));
  const tokenData = JSON.parse(decodeText);
  return tokenData;
}
