import { Select } from "antd";

export interface RepTypeOption {
  label: string;
  value: string;
  subtype?: {};
}

export default function SelectField(props: {
  label: string;
  name: string;
  handleChange: any;
  selecterLabel: string;
  options: RepTypeOption[];
  inputValue?: string;
  ommitEmptySelected?: boolean;
}) {
  return (
    <Select
      // onKeyUp={(event) =>
      //   event.key === "Enter" ? event.stopPropagation() : ""
      // }
      style={{ width: "100%" }}
      id={props.name}
      placeholder={props.label}
      onChange={(value) => props.handleChange(value, props.name)}
      options={props.options}
    >
      {props.ommitEmptySelected ? (
        false
      ) : (
        <Select.Option style={{ width: "100%" }} selected hidden>
          {props.label}
        </Select.Option>
      )}
      {props.options.map((option) => (
        <Select.Option
          style={{ width: "100%" }}
          key={option.label}
          value={option.value}
        >
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
}
