import { Button, DatePicker, Divider, Form, Input, Typography } from "antd";
import dayjs from "dayjs";
import {
  disabledFutureDates,
  disabledPastDates,
  FormDataTypes,
  InitialDataType,
} from "../../../../../pages/CreateAuction";

const { Text } = Typography;

export default function DeathDetails(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    console.log("prev", props.filledData);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      auctionData.soonestDateOfTransport = values.soonestDateOfTransport
        ? values.soonestDateOfTransport
        : dayjs().add(1, "day");
      auctionData.causeOfDeath = values.causeOfDeath;
      auctionData.dateOfDeath = values.dateOfDeath ? values.dateOfDeath : dayjs();
    });

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }

  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <>
      <Form
        name="patientMedicalDetails"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{ 
          soonestDateOfTransport: data.soonestDateOfTransport || dayjs().add(1, "day"),
          causeOfDeath: data.causeOfDeath,
          dateOfDeath: data.dateOfDeath || dayjs(),
         }}
      >
        <Text>Information about death</Text>
        <Divider />
        <Form.Item
          label={<Text>Soonest possible date of transport</Text>}
          name="soonestDateOfTransport"
        >
          <DatePicker
            showToday={false}
            style={{ width: "100%" }}
            defaultValue={dayjs().add(1, "day")}
            disabledDate={disabledPastDates}
          />
        </Form.Item>
        <Form.Item
          label={<Text>Cause of death</Text>}
          name="causeOfDeath"
        // rules={fieldIsRequired}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item label={<Text>Date of death</Text>} name="dateOfDeath">
          <DatePicker
            style={{ width: "100%" }}
            defaultValue={dayjs()}
            disabledDate={disabledFutureDates}
          />
        </Form.Item>

        <div>
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
