import RegistrationForm from "../components/ui/form/RegistrationForm";

export default function Registration() {

  return (
    <div>
      <RegistrationForm />
    </div>
  );
}
