import {Breadcrumb, Button, Col, Form, message, Row, Skeleton, Table, Tag} from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import React, {
  useContext,
  useMemo,
  useState,
} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import { DraftFunction, useImmer } from "use-immer";
import { serviceUrl } from "../AppConfig";
import UserContext, { getTokens, removeTokens } from "../context/AppContext";
import { auctionItemData, repType } from "../context/RepDataTypes";
import NumberField from "../components/ui/form/formElements/NumberField";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  formatRepData,
  repAdditionalDetails,
  repApplicantContacts,
  repDeathInformation,
  repFamilyContactInfo,
  repGeneralInformation,
  repKindOfAssistance,
  repLocationsInformation,
  repPatienInformationDetails,
  repPatienInformationGeneral,
  repPatienInformationOther,
} from "../context/handleFunctions";
import Title from "antd/es/typography/Title";
import {ClockCircleOutlined} from "@ant-design/icons";

export default function ViewAuction() {
  const [auctionData, setAuctionData] = useImmer<auctionItemData>({
    bids: [],
    id: "",
    name: "",
    created_at: "",
    auction_details: { repatriationType: "", repatriationSubtype: "" },
    bid_increment: "",
    ending_time: "",
    status: "",
    applicant: "",
    winning_bid: "",
  });

  const [repData, setRepData] = useState({
    location: {},
    destination: {},
    departureFrom: {},
    departureTo: {},
    soonestDateOfTransport: "",
    transportType: "",
    assistanceType: "",
  });
  const navigate = useNavigate();
  const { auctionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const BidHistoryColumns = [
    {
      title: "Offer ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => <>{amount} &euro;</>,
    },
  ];

  useMemo(() => {
    setIsLoading(true);
    const config = {
      url: "view_auction/",
      method: "get",
      baseURL: serviceUrl,
      headers: { Authorization: "Bearer " + getTokens().AccessToken },
      params: {
        userType: getTokens().userType,
        auctionId: auctionId,
      },
    };
    axios
      .request(config)
      .then((response: AxiosResponse) => {
        const auctionInformation: auctionItemData = response.data;
        setAuctionData(auctionInformation);
        const repInformation: any =
          auctionInformation.auction_details[
            auctionInformation.auction_details.repatriationType as keyof repType
          ];
        setRepData(repInformation);
        setIsLoading(false);
        handleInputChange(bidAmount, "");
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          removeTokens();
          navigate("/login", { replace: true });
        }
        setIsLoading(false);
      });
  }, [auctionId, setAuctionData]);

  const reversedLastThreeBids = auctionData.bids!.slice(-3);

  const { userState, setUserState } = useContext(UserContext);
  const [bidAmount, setBidAmount] = useState("");

  function handleInputChange(value: any, _name: string) {
    setBidAmount(value);
  }

  function placeBid(enteredBidAmount: string) {
    setUserState((userStateDraft) => {
      userStateDraft.isLoading = true;
    });
    messageApi.open({
      key,
      type: "loading",
      content: "Bidding",
      duration: 10,
    });
    setBidAmount(enteredBidAmount);
    const targetUrl = serviceUrl + "provider/bid/";
    const config = {
      headers: { Authorization: "Bearer " + getTokens().AccessToken },
    };
    const requestData = {
      userType: getTokens().userType,
      auctionId: auctionId,
      enteredBidAmount: bidAmount,
    };
    axios
      .post(targetUrl, requestData, config)
      .then((response: AxiosResponse) => {
        console.log("success", response);
        // setErrorFlag(false);
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "success",
          content: "Accepted",
          duration: 10,
        });
        const injectAuction: DraftFunction<auctionItemData[]> = (
          oldAuctionList: WritableDraft<auctionItemData>[]
        ) => {
          return oldAuctionList.map((auction) => {
            if (auction.id === response.data.id) {
              return response.data;
            } else {
              return auction;
            }
          });
        };
        // setAuctionData(injectAuction);
      })
      .catch((error: AxiosError) => {
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "error",
          content: "Rejected",
          duration: 4,
        });
        console.log("fail", error);
        // setErrorFlag(true);
      });
  }
  const repGeneralData = repGeneralInformation(auctionData, auctionId);
  const repDeathInformationData = repDeathInformation(repData);
  const repLocationsData = repLocationsInformation(repData);
  const repKindOfAssistanceData = repKindOfAssistance(repData);
  const repFamilyContactData = repFamilyContactInfo(repData);
  const repPatienDataGeneral = repPatienInformationGeneral(repData);
  const repPatienDataDetails = repPatienInformationDetails(repData);
  const repPatienDataOther = repPatienInformationOther(repData);
  const repAdditionalData = repAdditionalDetails(repData);
  const repApplicantContactsData = repApplicantContacts(repData);

  const dataSource = [
    {
      submission: '2023-02-27 22:39',
      id: '01869499-eb0d-4986-a540-18a514543a13',
      status: "Active",
      ending: 'Ended',
      type: 'regularFlight',
      subtype: 'business',
    }
  ];

  const columns = [
    {
      title: 'Application submission date',
      dataIndex: 'submission',
      key: 'submission',
    },
    {
      title: 'Application ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Application ending after',
      dataIndex: 'ending',
      key: 'ending'
    },
    {
      title: 'Repatriation Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Repatriation Subtype',
      dataIndex: 'subtype',
      key: 'subtype'
    },
  ];

  const dataSourceBid = [
    {
      input: <>
        <Form.Item
            name={auctionId}
            rules={[
              {
                required: false,
              },
            ]}
            validateStatus={"error" ? "" : ""}
            help={""}
            style={{
              maxWidth: "180px",
              marginTop: "24px",
            }}
        >
          <NumberField
              placeholder="Place amount here (€)"
              name="enteredBidAmount"
              handleChange={handleInputChange}
              inputValue={bidAmount}
          />
        </Form.Item>
      </>,
      action: <Button
          type="default"
          loading={userState.isLoading}
          onClick={() => placeBid(bidAmount)}
      >
        Place bid
      </Button>,
    }
  ];

  const columnsBid = [
    {
      title: 'Place your bid amount',
      dataIndex: 'input',
      key: 'input',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const dataPatient = [
    {
      name: 'Laurynas Dadlaisukas',
      birth: '2023-02-27 22:00',
      height: "180cm",
      weight: '90kg',
      diagnosis: 'Insomnia',
      hospitalDate: '2023-02-27 22:00',
      status: 'Active',
    }
  ];

  const columnsPatient = [
    {
      title: 'Name Surname',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date of birth',
      dataIndex: 'birth',
      key: 'birth',
    },
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height'
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight'
    },
    {
      title: 'Diagnosis',
      dataIndex: 'diagnosis',
      key: 'diagnosis'
    },
    {
      title: 'Date of addmition to Hospital',
      dataIndex: 'hospitalDate',
      key: 'hospitalDate'
    },
    {
      title: 'General status:',
      dataIndex: 'status',
      key: 'status'
    },
  ];

  return (
    <>
      {contextHolder}
      {isLoading ? (
        <>
          <Skeleton active />
        </>
      ) : (
        <>
          <Title
            level={3}
            style={{
              marginBottom: '24px'
            }}
          >
            Name of the Auction
            <Tag
                color="#87d068"
                icon={<ClockCircleOutlined />}
                style={{
                  marginLeft: "24px",
                }}
            >
              Active
            </Tag>
          </Title>

          <Breadcrumb
              style={{
                marginBottom: '40px'
              }}
          >
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to="#">Auctions</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="#">Name of the Auction</Link>
            </Breadcrumb.Item>
          </Breadcrumb>

          <Row>
            <Title
                level={4}
            >
              General information
            </Title>
            <Col span={24}>
              <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{hideOnSinglePage: true}}
              />
            </Col>
          </Row>

          <Row
          >
            <Title
                level={4}
            >Name of the Auction</Title>
            <Col span={24}>
              <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{hideOnSinglePage: true}}
              />
            </Col>
          </Row>

          <Row
          >
            <Title
                level={4}
            >
              Information about patient (Details)</Title>
            <Col span={24}>
              <Table
                  dataSource={dataPatient}
                  columns={columnsPatient}
                  pagination={{hideOnSinglePage: true}}
              />
            </Col>
          </Row>
          {formatRepData(repGeneralData)}
          {formatRepData(repDeathInformationData)}
          {formatRepData(repLocationsData)}
          {formatRepData(repKindOfAssistanceData)}
          {formatRepData(repFamilyContactData)}
          {formatRepData(repPatienDataGeneral)}
          {formatRepData(repPatienDataDetails)}
          {formatRepData(repPatienDataOther)}
          {formatRepData(repAdditionalData)}
          {formatRepData(repApplicantContactsData)}
          <Table
            dataSource={reversedLastThreeBids}
            columns={BidHistoryColumns}
            pagination={false}
            rowKey={(record) => record.id}
          />
          {userState.userType === "bidder" &&
            auctionData.status === "started" && (
              <Row>
                <Title
                    level={4}
                    type={"success"}
                >
                  Place your bid
                </Title>
                <Col span={24}>
                  <Table
                      dataSource={dataSourceBid}
                      columns={columnsBid}
                      pagination={{hideOnSinglePage: true}}
                  />
                </Col>
              </Row>
            )}
        </>
      )}
    </>
  );
}
