import LoginForm from "../components/ui/form/LoginForm";

export default function Login() {

  return (
    <>
      <LoginForm />
    </>
  );
}
