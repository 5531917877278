import { Button, DatePicker, Divider, Form, Input, Radio, Typography } from "antd";
import dayjs from "dayjs";
import {
  commonLocationFields,
  disabledPastDates,
  fieldIsRequired,
  FormDataTypes,
  InitialDataType
} from "../../../../../pages/CreateAuction";
import Title from "antd/es/typography/Title";

const { Text } = Typography;

export default function DestinationInformation(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {

  const repType = props.filledData.auction_details.repatriationType;
  const fromHospital = (props.filledData.auction_details[repType] as FormDataTypes).departureFrom.hospital
  const toHospital = (props.filledData.auction_details[repType] as FormDataTypes).departureTo.hospital
  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {

    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      if (!(auctionData.departureFrom)) {
        auctionData.departureFrom = {}
      }
      const departureFrom = auctionData.departureFrom;
      if (!(auctionData.departureTo)) {
        auctionData.departureTo = {}
      }
      const departureTo = auctionData?.departureTo;

      auctionData.soonestDateOfTransport = values.soonestDateOfTransport
        ? values.soonestDateOfTransport
        : dayjs().add(1, "day");
      departureFrom.hospital = values.destinationFrom;
      departureFrom.country = values.fromCountry;
      departureFrom.city = values.fromCity;
      departureFrom.fullAdress = values.fromFullAdress;
      departureFrom.doctorContacts = values.fromDoctorContacts;
      departureFrom.doctorLanguage = values.fromCommunicationLanguage;
      departureFrom.familyContacts = values.fromFamilyContacts;
      departureFrom.clientContacts = values.fromClientContacts;
      departureTo.hospital = values.destinationTo
      departureTo.country = values.toCountry;
      departureTo.city = values.toCity;
      departureTo.fullAdress = values.toFullAdress;
      departureTo.hospitalName = values.toHospitalName;
      departureTo.doctorContacts = values.toDoctorContacts;
    });
    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }

  const locationOptions = [
    {
      key: "hospital",
      label: "Hospital",
      value: true,
    },
    {
      key: "residence",
      label: "Current residence",
      value: false,
    },
  ];

  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <>
      <Form
        name="generalInformation"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{
          soonestDateOfTransport: data.soonestDateOfTransport || dayjs().add(1, "day"),
          destinationFrom: data.departureFrom.hospital === undefined ? true : data.departureFrom.hospital,
          fromCountry: data.departureFrom.country,
          fromCity: data.departureFrom.city,
          fromFullAdress: data.departureFrom.fullAdress,
          fromDoctorContacts: data.departureFrom.doctorContacts,
          fromCommunicationLanguage: data.departureFrom.doctorLanguage,
          fromFamilyContacts: data.departureFrom.familyContacts,
          fromClientContacts: data.departureFrom.clientContacts,
          destinationTo: data.departureTo.hospital === undefined ? true : data.departureTo.hospital,
          toCountry: data.departureTo.country,
          toCity: data.departureTo.city,
          toFullAdress: data.departureTo.fullAdress,
          toHospitalName: data.departureTo.hospitalName,
          toDoctorContacts: data.departureTo.doctorContacts
        }}
      >
        <Title
            level={4}
            style={{
              margin: "48px 0 24px 0",
            }}
        >From destination</Title>
        <Form.Item name="destinationFrom">
          <Radio.Group
            defaultValue={true}
            options={locationOptions}
            onChange={(event) => {
                props.setFilledData((draftFilledData: any) => {
                  const auctionData = draftFilledData.auction_details[repType];
                  auctionData.departureFrom.hospital = event.target.value
                })
            }}
          />
        </Form.Item>

        <div style={{ marginLeft: "20px" }}>
          {commonLocationFields("from")}
          {(fromHospital === undefined || fromHospital === true) && (
            <Form.Item
              label={<Text>Doctor contact details</Text>}
              name="fromDoctorContacts"
            // rules={fieldIsRequired}
            >
              <Input.TextArea rows={1} allowClear />
            </Form.Item>
          )}
          <Form.Item
            label={<Text>Language for communication</Text>}
            name="fromCommunicationLanguage"
          // rules={fieldIsRequired}
          >
            <Input.TextArea rows={1} allowClear />
          </Form.Item>
          <Form.Item
            label={<Text>Family member contact details</Text>}
            name="fromFamilyContacts"
          // rules={fieldIsRequired}
          >
            <Input.TextArea rows={1} allowClear />
          </Form.Item>
          {fromHospital === false && (
            <Form.Item
              label={<Text>Client contact details</Text>}
              name="fromClientContacts"
              rules={fieldIsRequired}
            >
              <Input.TextArea rows={1} allowClear />
            </Form.Item>
          )}
        </div>
        
        <Text>To destination</Text>
        <Divider />
        <Form.Item name="destinationTo">
          <Radio.Group
            defaultValue={true}
            options={locationOptions}
            onChange={(event) => {
                props.setFilledData((draftFilledData: any) => {
                  const auctionData = draftFilledData.auction_details[repType];
                  auctionData.departureTo.hospital = event.target.value
                })
            }}
          />
        </Form.Item>

        <div style={{ marginLeft: "20px" }}>
          {commonLocationFields("to")}
          {(toHospital === undefined || toHospital) && (
            <>
              <Form.Item
                label={<Text>Name of the hospital</Text>}
                name="toHospitalName"
              // rules={fieldIsRequired}
              >
                <Input allowClear />
              </Form.Item>
              <Form.Item
                label={<Text>Doctor contact details</Text>}
                name="toDoctorContacts"
                rules={fieldIsRequired}
              >
                <Input.TextArea rows={1} allowClear />
              </Form.Item>
            </>
          )}
        </div>
        <Form.Item
          label={<Text>Soonest possible date of transport</Text>}
          name="soonestDateOfTransport"
        >
          <DatePicker
            style={{ width: "100%" }}
            // defaultValue={}
            disabledDate={disabledPastDates}
          />
        </Form.Item>
        <div>
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
