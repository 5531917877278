import React, { useContext } from "react";
import UserContext from "../context/AppContext";
import Login from "./Login";
import {Breadcrumb, Card, Col, Row, Statistic} from "antd";
import Title from "antd/es/typography/Title";
import {Link} from "react-router-dom";

export default function Home() {
  const { userState } = useContext(UserContext);

  return (
      <>{userState.loggedIn ? <>

        <Title
            level={3}
            style={{
              marginBottom: '24px'
            }}
        >
          Dashboard
        </Title>

        <Breadcrumb
            style={{
              marginBottom: '40px'
            }}
        >
          <Breadcrumb.Item>
            <Link to="/">Dashboard</Link>
          </Breadcrumb.Item>

        </Breadcrumb>
        <Row
          style={{
            padding: 0,
          }}
        >
          <Col
              span={12}
              style={{padding:"24px"}}
          >
            <Card bordered={true}>
            <Statistic
                title="Total Auctions"
                value={112893}
            />
            </Card>
          </Col>

          <Col
              span={12}
              style={{padding:"24px"}}
          >
            <Card bordered={true}>
            <Statistic
                title="My Auctions"
                value={112893}
                precision={2}
            />
            </Card>
          </Col>
          <Col
              span={8}
              style={{padding:"24px"}}
          >
            <Card bordered={true}>
            <Statistic
                  title="Active Auctions"
                  value={28}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col
              span={8}
              style={{padding:"24px"}}
          >
            <Card bordered={true}>

            <Statistic
                  title="Inactive Auctions"
                  value={11}
                  precision={0}
              />

            </Card>
          </Col>

          <Col
              span={8}
              style={{padding:"24px"}}
          >
            <Card bordered={true}>

              <Statistic
                  title="Inactive Auctions"
                  value={11}
                  precision={0}
              />

            </Card>
          </Col>
        </Row>
      </> : <Login />}</>
  )
};