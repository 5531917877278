import { List, Tag } from "antd";
import { showLocalizedDate, getTimeDifference } from "./handleDateFunctions";

/**
 * Function takes an array of objects listItem and formats it into a list using
 *  the List component from Ant Design. The function filters out any invalid items
 *  in the listItem array and only renders items with label, value, isTitle, or
 *  isListName properties. It then maps the remaining items to a List.Item component.
 *
 * If listItem is falsy, the function returns an empty JSX element.
 * If the filtered listItem array is empty or only contains an item with isListName,
 *  the function returns an empty JSX element.
 *
 * @param listItem: An array of objects to be formatted into a list.
 * @returns A JSX element representing the formatted list.
 */
export function formatRepData(listItem: any[] = []) {
  if (!listItem) {
    return <></>;
  }
  const Items = [...listItem];

  const filteredItems = Items.filter(
    (item) => item.label && (item.isTitle || item.isListName || item.value)
  );

  const hasValidItems = filteredItems.some((item) => {
    return item.isListName ? item.label || item.value : true;
  });

  if (
    !hasValidItems ||
    (filteredItems.length === 1 && filteredItems[0].isListName)
  ) {
    return <></>;
  }

  return (
    <List
      dataSource={filteredItems}
      renderItem={({ label, value, bold, isTitle, isListName }) => (
        <List.Item style={{ paddingLeft: isTitle || isListName ? 5 : 24 }}>
          <span
            style={{
              fontWeight: isTitle || bold || isListName ? "bold" : "normal",
              fontSize: isTitle || isListName ? 20 : 14,
            }}
          >
            {isTitle ? <>{label}</> : <>{label}: </>}
          </span>
          {value}
        </List.Item>
      )}
    />
  );
}

export function repGeneralInformation(input: any, auctionId: any) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: input.name,
      isTitle: true,
    },
    {
      label: "General information",
      isListName: true,
    },
    {
      label: "Application submission date",
      value: showLocalizedDate(input.created_at),
    },
    {
      label: "Application ID",
      value: auctionId,
    },
    {
      label: "Status",
      value:
        input.status === "started" ? (
          <Tag style={{ color: "green", float: "left" }}>ACTIVE</Tag>
        ) : (
          <Tag style={{ color: "red", float: "left" }}>INACTIVE</Tag>
        ),
    },
    {
      label: "Application endig after",
      value: getTimeDifference(new Date(), new Date(input.ending_time)),
    },
    {
      label: "Repatriation Type",
      value: input.auction_details.repatriationType,
    },
    {
      label: "Repatriation Subtype",
      value: input.auction_details.repatriationSubtype,
    },
  ];
  return listItem;
}

export function repDeathInformation(input: any) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: "Information about death and beginning of repatriation",
      isListName: true,
    },
    {
      label: "Soonest possible date of transport",
      value: input.dateOfDeath ? input.soonestDateOfTransport : "",
      bold: true,
    },
    {
      label: "Couse of death",
      value: input.causeOfDeath,
    },
    {
      label: "Date of death",
      value: input.dateOfDeath,
    },
  ];
  return listItem;
}

export function repLocationsInformation(input: any) {
  if (!input) {
    return [];
  }
  let locationInformation = input.location || input.departureFrom;
  let destinationInformation = input.destination || input.departureTo;
  const listItem = [
    {
      label: !input.dateOfDeath
        ? "Departure details"
        : "Repatriation details (places)",
      isListName: true,
    },
    {
      label: "Departure From",
      value: " ",
      bold: true,
    },
    ...getLocationDetailsItem(locationInformation),
    {
      label: "Departure To",
      value: " ",
      bold: true,
    },
    ...getLocationDetailsItem(destinationInformation),
    {
      label: "Soonest possible date of transport",
      value: !input.dateOfDeath ? input.soonestDateOfTransport : "",
      bold: true,
    },
  ];
  function getLocationDetailsItem(input: any) {
    const locationsListItem = [
      {
        label: "Destination Type",
        value: input.destinationType,
      },
      {
        label: "Country",
        value: input.country || input.coutry,
      },
      {
        label: "City",
        value: input.city,
      },
      {
        label: "Full Address",
        value: input.fullAdress,
      },
      {
        label: "Doctor Contacts",
        value: input.doctorContacts,
      },
      {
        label: "Doctor Language",
        value: input.doctorLanguage,
      },
      {
        label: "Family Contacts",
        value: input.familyContacts,
      },
      {
        label: "Client Contacts",
        value: input.clientContacts || input.contacts,
      },
    ];
    return locationsListItem;
  }
  return listItem;
}

export function repKindOfAssistance(input: any) {
  if (!input) {
    return [];
  }
  let needsList = "";
  if (input.specialFacilitiesNeeded === "yes") {
    needsList = input.specialFacilitiesNeededList.join(", ");
  }
  let lifeSupport = "";
  const lifeSupportFacilities: string[] = input.lifeSupportFacilities || [];
  if (lifeSupportFacilities.includes("lifeSupport")) {
    lifeSupport = input.lifeSupportFacilitiesDescription;
  }
  let oxygen = "";
  const oxygenFacilities: string[] = input.oxygenFacilities || [];
  if (oxygenFacilities.includes("oxygen")) {
    oxygen = input.oxygenFacilitiesDescription;
  }
  const listItem = [
    {
      label: "Kind of Assistant needed",
      isListName: true,
    },
    {
      label: "Kind of assistance",
      value: input.assistanceType,
    },
    {
      label: "Special facilities necessary during transport",
      value: input.specialFacilitiesNeeded,
    },
    {
      label: "List of needs",
      value: needsList,
    },
    {
      label: "Medication needed",
      value: input.medicationNeededComment,
    },
    {
      label: "Life supporting facilities",
      value: lifeSupport,
    },
    {
      label: "Oxygen",
      value: oxygen,
    },
  ];
  return listItem;
}

export function repPatienInformationGeneral(input: any) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: !input.dateOfDeath
        ? "Information about Patient (General information)"
        : "Repatriated Person Details",
      isListName: true,
    },
    {
      label: "Country of residence",
      value: input.countryOfResidence,
    },
    {
      label: "Name, surname",
      value: input.patientNameSurname || input.firstName,
    },
    {
      label: "Date of birth",
      value: input.dateOfBirth,
    },
    {
      label: "Height",
      value: input.height,
    },
    {
      label: "Weight",
      value: input.weight,
    },
    {
      label: "Diagnosis",
      value: input.diagnosis,
    },
    {
      label: "Date of addmition to Hospital",
      value: input.dateOfAdmission,
    },
    {
      label: "General status",
      value: input.generalStatus,
    },
  ];
  return listItem;
}

export function repFamilyContactInfo(input: any) {
  if (!input) {
    return [];
  }
  if (!input.familyMember) {
    return [];
  }
  const listItem = [
    {
      label: "Family member contact",
      isListName: true,
    },
    {
      label: "Relation",
      value: input.familyMember.relation,
    },
    {
      label: "Frist name",
      value: input.familyMember.name,
    },
    {
      label: "Last Name",
      value: input.familyMember.surname,
    },
    {
      label: "Phone number",
      value: input.familyMember.phoneNumber,
    },
    {
      label: "Email",
      value: input.familyMember.email,
    },
  ];
  return listItem;
}

export function repPatienInformationDetails(input: any) {
  if (!input) {
    return [];
  }
  const physicalAbility = Array.isArray(input.physicalAbility)
    ? input.physicalAbility.join(", ")
    : "";
  const immobilized = Array.isArray(input.physicalAbilityImmobilized)
    ? input.physicalAbilityImmobilized.join(", ")
    : "";
  const listItem = [
    {
      label: "Information about Patient (Details)",
      isListName: true,
    },
    {
      label: "Blood presure",
      value: input.bloodPressure,
    },
    {
      label: "Hear rate",
      value: input.heartRate,
    },
    {
      label: "State of consciousness + GCS",
      value: input.consciousness,
    },
    {
      label: "Neurological status",
      value: input.neurologicalStatus,
    },
    {
      label: "Respiratory tract status",
      value: input.respiratoryTractStatus || input.respiratortTractStatus,
    },
    {
      label: "Cardiovascular system status",
      value: input.cardiovascularSystemStatus,
    },
    {
      label: "Physical ability",
      value: physicalAbility,
    },
    {
      label: "Patient Immobilized",
      value: immobilized,
    },
    {
      label: "Patient immobilized due to other issue",
      value: input.physicalAbilityImmobilizedOther,
    },
    {
      label: "Restrictions concerning position of the body",
      value: input.physicalAbilityRestrictedDescription,
    },
    {
      label: "Pain intensity",
      value: input.painIntensity,
    },
    {},
  ];
  return listItem;
}

export function repPatienInformationOther(input: any) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: "Information about Patient (Other medical problems)",
      isListName: true,
    },
    {
      label: "Other medical problems",
      value: input.otherMedicalProblems,
    },
    {
      label: "Peripheral vein catheterization",
      value: input.peripheralVeinCatheterization,
    },
    {
      label: "Central vein catheter",
      value: input.centralVeinCatheter,
    },
    {
      label: "Urinal catheter",
      value: input.urinalCatheter,
    },
    {
      label: "Stool incontinence",
      value: input.stoolIncontinence,
    },
    {
      label: "Naso-gastric tube",
      value: input.nasoGastricTube,
    },
    {
      label: "Tracheostomy",
      value: input.tracheostomy,
    },
    {
      label: "Pulmonary ventilation",
      value: input.pulmonaryVentilation,
    },
    {
      label: "Suctioning of mucus and other fluids",
      value: input.suctioningOfFluids,
    },
    {
      label:
        "Medication required for the transport (name, dosage, application)",
      value: input.medicationRequiredForTransport,
    },
  ];
  return listItem;
}

export function repAdditionalDetails(input: any) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: "Additional details on repatriation",
      isListName: true,
    },
    {
      label: "Other institution involved",
      value: (input.otherInstitution && input.otherInstitution.name || ""),
    },
    {
      label: "Special requirements",
      value: input.specialRequirements,
    },
    {
      label: "Offer delivery deadline date and time",
      value: input.offerDeliveryDeadline,
    },
  ]
  return listItem;
}

export function repApplicantContacts(input: any) {
  if (!input) {
    return [];
  }
  if (!input.applicantContacts) {
    return [];
  }
  const listItem = [
    {
      label: "Applicant contact details",
      isListName: true,
    },
    {
      label: "First Name",
      value: input.applicantContacts.name,
    },
    {
      label: "Email",
      value: input.applicantContacts.email,
    },
    {
      label: "Phone number",
      value: input.applicantContacts.phone,
    },
    {
      label: "Company name",
      value: input.applicantContacts.companyName,
    },
    {
      label: "Special requirements",
      value: input.applicantContacts.specialRequirements,
    },
  ];
  return listItem;
}

//__________________
export function repatriationLocation(input: any) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: "Destination Type",
      value: input.destinationType,
    },
    {
      label: "Country",
      value: input.country || input.coutry,
    },
    {
      label: "City",
      value: input.city,
    },
    {
      label: "Full Address",
      value: input.fullAdress,
    },
    {
      label: "Doctor Contacts",
      value: input.doctorContacts,
    },
    {
      label: "Doctor Language",
      value: input.doctorLanguage,
    },
    {
      label: "Family Contacts",
      value: input.familyContacts,
    },
    {
      label: "Client Contacts",
      value: input.clientContacts || input.contacts,
    },
  ];
  return listItem;
}

export function generalAuctionInformation(input: any, auctionId: any) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: "Application submission date",
      value: showLocalizedDate(input.created_at),
    },
    {
      label: "Application ID",
      value: auctionId,
    },
    {
      label: "Status",
      value:
        input.status === "started" ? (
          <Tag style={{ color: "green", float: "left" }}>ACTIVE</Tag>
        ) : (
          <Tag style={{ color: "red", float: "left" }}>INACTIVE</Tag>
        ),
    },
    {
      label: "Application endig after",
      value: getTimeDifference(new Date(), new Date(input.ending_time)),
    },
  ];
  return listItem;
}

export function repASAP(input: string) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: "Date ant time",
      value: input,
    },
  ];
  return listItem;
}

export function repTransportTypeToFromAircraft(input: string) {
  if (!input) {
    return [];
  }
  const listItem = [
    {
      label: "Transport type",
      value: input,
    },
  ];
  return listItem;
}
