import { Button, Checkbox, Divider, Form, Input, Radio, Typography } from "antd";
import { fieldIsRequired, FormDataTypes, InitialDataType } from "../../../../../pages/CreateAuction";

const { Text } = Typography;

export default function PatientMedicalDetails(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    console.log("prev", props.filledData);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      auctionData.heartRate = values.heartRate
      auctionData.bloodPressure = values.bloodPressure
      auctionData.consciousness = values.consciousness
      auctionData.painIntensity = values.painIntensity
      auctionData.physicalAbility = values.physicalAbility
      auctionData.neurologicalStatus = values.neurologicalStatus
      auctionData.respiratoryTractStatus = values.respiratoryTractStatus
      auctionData.cardiovascularSystemStatus = values.cardiovascularSystemStatus
      auctionData.physicalAbilityImmobilized = values.physicalAbilityImmobilized
      auctionData.physicalAbilityImmobilizedOther = values.physicalAbilityImmobilizedOther
      auctionData.physicalAbilityRestrictedDescription = values.physicalAbilityRestrictedDescription
    });

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }

  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <Form
      name="patientMedicalDetails"
      layout="vertical"
      style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
      onFinish={next}
      initialValues={{
        heartRate: data.heartRate,
        physicalAbility: data.physicalAbility,
        bloodPressure: data.bloodPressure,
        consciousness: data.consciousness,
        painIntensity: data.painIntensity,
        neurologicalStatus: data.neurologicalStatus,
        physicallyImpaired: data.physicallyImpaired,
        respiratoryTractStatus: data.respiratoryTractStatus,
        physicalAbilityRestricted: data.physicalAbilityRestricted,
        cardiovascularSystemStatus: data.cardiovascularSystemStatus,
        physicalAbilityImmobilized: data.physicalAbilityImmobilized,
        physicalAbilityImmobilizedOther: data.physicalAbilityImmobilizedOther,
        physicalAbilityRestrictedDescription: data.physicalAbilityRestrictedDescription,
      }}
    >
      <Text>Information about Patient (Details)</Text>
      <Divider />
      <Form.Item
        label={<Text>Blood pressure</Text>}
        name="bloodPressure"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        label={<Text>Heart rate</Text>}
        name="heartRate"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        label={<Text>State of consciousness + GCS</Text>}
        name="consciousness"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        label={<Text>Neurological status</Text>}
        name="neurologicalStatus"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        label={<Text>Respiratory tract status</Text>}
        name="respiratoryTractStatus"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        label={<Text>Cardiovascular system status</Text>}
        name="cardiovascularSystemStatus"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>

      <Form.Item
        name="physicallyImpaired"
      >
        <Radio.Group
          defaultValue={false}
          onChange={(event) => {
              props.setFilledData((draftFilledData: any) => {
                const auctionData = draftFilledData.auction_details[repType];
                auctionData.physicallyImpaired = event.target.value
              })
          }}
          options={[
            {
              label: "Walking without assistance",
              value: false,
            },
            {
              label: "Walking with assistance",
              value: true,
            },
          ]}
        />
      </Form.Item>
      {data.physicallyImpaired && (
        <>
          <Form.Item name="physicalAbility">
            <Checkbox.Group
              value={Array.isArray(data.physicalAbility) ? data.physicalAbility : []}
              onChange={(checkedValues) =>
                props.setFilledData((draftFilledData: any) => {
                  const auctionData = draftFilledData.auction_details[repType];
                  auctionData.physicalAbility = checkedValues;
                })
              }
              options={[
                { label: "Sitting", value: "sitting" },
                { label: "Bed ridden", value: "bedRidden" },
                { label: "Immobilization", value: "immobilization" },
              ]}
            />
          </Form.Item>
          {(data.physicalAbility && data.physicalAbility.includes("immobilization")) && (
            <>
              <Form.Item name="physicalAbilityImmobilized">
                <Checkbox.Group
                  value={Array.isArray(data.physicalAbilityImmobilized) ? data.physicalAbilityImmobilized : []}
                  onChange={(checkedValues) =>
                    props.setFilledData((draftFilledData: any) => {
                      const auctionData = draftFilledData.auction_details[repType];
                      auctionData.physicalAbilityImmobilized = checkedValues;
                    })
                  }
                  options={[
                    { label: "Cast", value: "cast" },
                    { label: "Plaster", value: "plaster" },
                    { label: "Splint", value: "splint" },
                    { label: "Orthesis", value: "orthesis" },
                    { label: "Other", value: "other" },
                  ]}
                />
              </Form.Item>
              {(data.physicalAbilityImmobilized && data.physicalAbilityImmobilized.includes("other")) && (
                <Form.Item
                  label={<Text>Description</Text>}
                  name="physicalAbilityImmobilizedOther"
                  rules={fieldIsRequired}
                >
                  <Input allowClear />
                </Form.Item>
              )}
            </>
          )}
          <Form.Item
            name="physicalAbilityRestricted"
          >
            <Checkbox.Group
              value={Array.isArray(data.physicalAbilityRestricted) ? data.physicalAbilityRestricted : []}
              onChange={(checkedValues) =>
                props.setFilledData((draftFilledData: any) => {
                  const auctionData = draftFilledData.auction_details[repType];
                  auctionData.physicalAbilityRestricted = checkedValues;
                })
              }
              options={[
                { label: "Restrictions concerning position of the body", value: "restrictedPosition" }
              ]}
            />
          </Form.Item>
          {(data.physicalAbilityRestricted && data.physicalAbilityRestricted.includes("restrictedPosition")) && (
            <Form.Item
              label={<Text>Describe restrictions</Text>}
              name="physicalAbilityRestrictedDescription"
              rules={fieldIsRequired}
            >
              <Input allowClear />
            </Form.Item>
          )}
        </>
      )}
      <Form.Item label={<Text>Pain intensity</Text>} name="painIntensity">
        <Radio.Group
          defaultValue={"noPain"}
          onChange={(event) => {
              props.setFilledData((draftFilledData: any) => {
                const auctionData = draftFilledData.auction_details[repType];
                auctionData.painIntensity = event.target.value
              })
          }}
          options={[
            { label: "No pain", value: "noPain" },
            {
              label: "Occasionally in pain - oral pain killer adequate",
              value: "ocasional",
            },
            { label: "Regular painkiller medication needed", value: "high" },
          ]}
        />
      </Form.Item>

      <div>
        <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
          Previous
        </Button>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </div>
    </Form>
  );
}
