import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { serviceUrl } from "../AppConfig";
import PasswordField from "../components/ui/form/formElements/PasswordField";
import { Button, Col, Form, message, Row, Typography } from "antd";
import UserContext, { removeTokens } from "../context/AppContext";

const { Title, Text } = Typography;

export default function Reset() {
  removeTokens();

  const [changePassword, setChangePassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [searchParams] = useSearchParams();
  const { userState, setUserState } = useContext(UserContext);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  useEffect(() => {
    console.log("changePassword", changePassword);
  }, [changePassword]);
  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  function handlePasswordChange(value: string, name: string) {
    setChangePassword((prevChangePassword) => ({
      ...prevChangePassword,
      [name]: value,
    }));
  }

  const navigate = useNavigate();

  function handleSave() {
    let newPasswordError = "";
    let conPasswordError = "";

    if (changePassword.newPassword.length < 12) {
      newPasswordError = "New password must be at least 12 characters long";
    } else if (!/\d/.test(changePassword.newPassword)) {
      newPasswordError = "New password must contain at least one number";
    } else if (!/[a-z]/.test(changePassword.newPassword)) {
      newPasswordError =
        "New password must contain at least one lowercase letter";
    } else if (!/[A-Z]/.test(changePassword.newPassword)) {
      newPasswordError =
        "New password must contain at least one uppercase letter";
    } else {
      newPasswordError = "";
    }

    if (changePassword.confirmPassword !== changePassword.newPassword) {
      conPasswordError = "Passwords do not match";
    } else {
      conPasswordError = "";
    }
    setErrors(() => ({
      newPassword: newPasswordError,
      confirmPassword: conPasswordError,
    }));
    if (newPasswordError === "" && conPasswordError === "") {
      console.log("Password checks - passed");
      initiateChange();
    }
  }

  function initiateChange() {
    setUserState((userStateDraft) => {
      userStateDraft.isLoading = true;
    });
    messageApi.open({
      key,
      type: "loading",
      content: "Initiating...",
      duration: 10,
    });
    const config = {
      url: "reset/",
      method: "post",
      baseURL: serviceUrl,
      data: {
        hash: searchParams.get("hash"),
        userType: searchParams.get("userType"),
        email: searchParams.get("email"),
        time: searchParams.get("time"),
        newPassword: changePassword.newPassword,
      },
    };
    axios
      .request(config)
      .then(function (_response: AxiosResponse) {
        // handle success
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "success",
          content: "Changed",
          duration: 1,
          onClose() {
            navigate("/login", { replace: true });
          },
        });
      })
      .catch(function (_error: AxiosError) {
        // handle error
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "error",
          content: "Error",
          duration: 4,
        });
      });
  }

  return (
    <>
      {contextHolder}
      <Title
        level={3}
        style={{
          marginBottom: "24px",
        }}
      >
        Reset Password
      </Title>
      <Row>
        <Col xs={24} xl={8}></Col>
        <Col xs={24} xl={8}>
          <Form
            layout="vertical"
            style={{ maxWidth: "420px", margin: "0 auto" }}
            onKeyUp={(event) => (event.key === "Enter" ? handleSave() : "")}
          >
            <Form.Item
              label={<Text>Enter new password</Text>}
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your old password",
                },
              ]}
              validateStatus={errors.newPassword !== "" ? "error" : ""}
              help={errors.newPassword}
            >
              <PasswordField
                placeholder=""
                name="newPassword"
                handleChange={handlePasswordChange}
              />
            </Form.Item>
            <Form.Item
              label={<Text>Re-enter password</Text>}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your old password",
                },
              ]}
              validateStatus={errors.confirmPassword !== "" ? "error" : ""}
              help={errors.confirmPassword}
            >
              <PasswordField
                placeholder=""
                name="confirmPassword"
                handleChange={handlePasswordChange}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                loading={userState.isLoading}
                danger
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                type="default"
                onClick={() =>
                  navigate("/profile", {
                    replace: true,
                  })
                }
                style={{
                  marginLeft: "12px",
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} xl={8}></Col>
      </Row>
    </>
  );
}
