import { message } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { serviceUrl } from "../AppConfig";
import UserContext, { getTokens, removeTokens } from "../context/AppContext";

export default function Logout() {
  const navigate = useNavigate();
  const { setUserState } = useContext(UserContext);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const clearUserData = () => {
    setUserState((userStateDraft) => {
      userStateDraft.loggedIn = false;
      userStateDraft.userType = "";
      userStateDraft.userDetails.firstName = "";
      userStateDraft.userDetails.lastName = "";
      userStateDraft.userDetails.email = "";
      userStateDraft.userDetails.phoneNumber = "";
      userStateDraft.notifications = [];
    });
  };

  const targetUrl = serviceUrl + "logout/";
  const payload = {
    refreshToken: getTokens().RefreshToken,
  };
  const config = {
    headers: { Authorization: "Bearer " + getTokens().AccessToken },
  };

  function openMessage() {
    messageApi.open({
      key,
      type: "loading",
      content: "Logout - started",
      duration: 10,
    });
    axios
      .post(targetUrl, payload, config)
      .then(function (_response: AxiosResponse) {
        messageApi.open({
          key,
          type: "success",
          content: "Logout - successful",
          duration: 2,
          onClose() {
            navigate("/login", { replace: true });
          },
        });
        removeTokens();
        clearUserData();
      })
      .catch(function (error: AxiosError) {
        messageApi.open({
          key,
          type: "error",
          content: "Logout - unsuccessful, stopping session",
          duration: 2,
          onClose() {
            navigate("/login", { replace: true });
          },
        });
        removeTokens();
        clearUserData();
      });
  }
  useEffect(() => {
    openMessage();
  }, []);

  return <>{contextHolder}</>;
}
