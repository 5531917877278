// import { ChangeEventHandler } from "react";
import { Form, Input } from "antd";

export default function EmailField(props: {
  placeholder: string;
  name: string;
  handleChange: (value: string, name: string) => void;
  inputValue?: string;
}) {

  function handleChangeInput (event: React.ChangeEvent<HTMLInputElement>) {
    props.handleChange(event.target.value, props.name);
  }

  return (
    // <Form.Item
    //   rules={[
    //     { 
    //       type: "email",
    //       // required: true,
    //       // pattern: new RegExp("/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/"),
    //       // message: "Enter a valid email address!",
    //     },
    //   ]}
    // >
      <Input 
        placeholder={props.placeholder}
        name={props.name}
        onChange={handleChangeInput}
      />
    // </Form.Item>
  );
}
