import { useMemo, useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { serviceUrl } from "../AppConfig";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { useImmer } from "use-immer";
import { getTokens, removeTokens } from "../context/AppContext";
import AuctionsTable from "../components/AuctionsTable";
import { auctionItemData } from "../context/RepDataTypes";
import Title from "antd/es/typography/Title";


export default function Auctions_() {
  const [auctions, setAuctions] = useImmer<auctionItemData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  useMemo(() => {
    const config = {
      headers: { Authorization: "Bearer " + getTokens().AccessToken },
      params: { userType: getTokens().userType },
    };
    const targetUrl = serviceUrl + "provider/auctions/";

    setIsLoading(true);

    axios
      .get<auctionItemData[]>(targetUrl, config)
      .then((response: AxiosResponse<auctionItemData[]>) => {
        setAuctions(response.data);
        setIsLoading(false);
      })
      .catch((error: AxiosError) => {
        console.error(error);
        setIsLoading(false);
        if (error.response?.status === 401) {
          removeTokens();
        }
        navigate("/login", { replace: true });
      });
  }, []);

  return (
    <>
      {auctions && (
        <Layout
          style={{
            background: "none",
            padding: "0px 32px",
          }}
        >
          <Title
            level={3}
            style={{
              marginBottom: "48px",
            }}
          >
            Auctions
          </Title>
          <AuctionsTable
            auctionItems={auctions}
            auctionUpdate={setAuctions}
            loadingStatus={isLoading}
          />
        </Layout>
      )}
    </>
  );
}
