import { Form, message, Modal } from "antd";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { serviceUrl } from "../../../AppConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import SelectField from "../form/formElements/SelectField";
import { userTypeOptions } from "../../commonOptions/userTypeOptions";
import EmailField from "../form/formElements/EmailField";
import UserContext from "../../../context/AppContext";

export default function ForgotPasswordModal(props: {
  show: boolean;
  onCloseButton: () => void;
  selectedUserType: string;
  email: string;
}) {
  const { userState, setUserState } = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: "",
    userType: "",
  });
  useEffect(() => {
    console.log(forgotPasswordData);
  }, [forgotPasswordData]);
  const [typeSelectorShow, setTypeSelectorShow] = useState(false);

  function handlePasswordReset(value: string, name: string) {
    setForgotPasswordData((prevForgotPasswordData) => ({
      ...prevForgotPasswordData,
      [name]: value,
    }));
  }

  function reqForgotPassword() {
    setUserState((userStateDraft) => {
      userStateDraft.isLoading = true;
    });
    const targetUrl = serviceUrl + "request_reset/";
    messageApi.open({
      key,
      type: "loading",
      content: "Sending email",
      duration: 10,
    });

    axios
      .post(targetUrl, {
        ...forgotPasswordData,
      })
      .then(function (response: AxiosResponse) {
        if (response.data === "SelectUserType") {
          messageApi.open({
            key,
            type: "warning",
            content: "User type not found, please select",
            duration: 2,
          });
          setTypeSelectorShow(true);
          setUserState((userStateDraft) => {
            userStateDraft.isLoading = false;
          });
        } else {
          // display success message start delay for close
          // close Modal
          messageApi.open({
            key,
            type: "success",
            content: "Email - sent",
            duration: 2,
            onClose() {
              props.onCloseButton();
              setUserState((userStateDraft) => {
                userStateDraft.isLoading = false;
              });
            },
          });
        }
        // return !props.show;
      })
      .catch(function (_error: AxiosError) {
        messageApi.open({
          key,
          type: "error",
          content: "Email - not sent",
          duration: 2,
          onClose() {
            setUserState((userStateDraft) => {
              userStateDraft.isLoading = false;
            });
          },
        });
        // return props.show;
      });
  }

  return (
    <Modal
      open={props.show}
      title="Password reset"
      centered
      okText="Send email"
      onOk={reqForgotPassword}
      confirmLoading={userState.isLoading}
      onCancel={() => {
        props.onCloseButton();
        setTypeSelectorShow(false);
      }}
    >
      {contextHolder}
      {typeSelectorShow ? (
        <Form.Item
          style={{
            marginTop: "32px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectField
            label="Select user type"
            name="userType"
            handleChange={handlePasswordReset}
            selecterLabel="Your user type"
            ommitEmptySelected={true}
            options={userTypeOptions}
          />
        </Form.Item>
      ) : (
        <Form.Item
          style={{
            marginTop: "32px",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <EmailField
            handleChange={handlePasswordReset}
            placeholder="Email address"
            name="email"
          />
        </Form.Item>
      )}
    </Modal>
  );
}
