import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Reset from "./pages/Reset";
import UserContext, { getTokens, UserState } from "./context/AppContext";
import Auctions from "./pages/Auctions";
import { serviceUrl } from "./AppConfig";
import axios, { AxiosError, AxiosResponse } from "axios";
import MyAuctions from "./pages/MyAuctions";
import ViewAuction from "./pages/ViewAuction";
import Notifications from "./pages/Notifications";
import { useImmer } from "use-immer";
import extractDataFromToken from "./components/commonOptions/getDataFromToken";
import ProfileForm from "./components/ui/form/ProfileForm";
import Profile from "./pages/Profile";
import Logout from "./pages/Logout";
import ChangePasswordForm from "./components/ui/form/ChangePasswordForm";
import {ConfigProvider} from "antd";
import CreateAuction from "./pages/CreateAuction";

function App() {
  const [userState, setUserState] = useImmer<UserState>({
    userType: "",
    userDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      country: "",
      city: "",
      address: "",
      companies: [],
    },
    loggedIn: false,
    notifications: [],
    isLoading: false
  });
  useEffect(() => {
    console.log("loggedIn", userState.loggedIn);
  }, [userState.loggedIn]);
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    const config = {
      headers: { Authorization: "Bearer " + getTokens().AccessToken },
    };
    const dataFromToken = extractDataFromToken(getTokens().IdToken);
    const targetUrl = serviceUrl + "validate_session/";

    if (getTokens().AccessToken !== "" || location.pathname !== "/reset") {
      axios
        .get(targetUrl, config)
        .then(function (_response: AxiosResponse) {
          setUserState((userStateDraft) => {
            userStateDraft.loggedIn = true;
            userStateDraft.userType = getTokens().userType;
            userStateDraft.userDetails.firstName = dataFromToken.name;
            userStateDraft.userDetails.lastName = dataFromToken.family_name;
            userStateDraft.userDetails.email = dataFromToken.email;
            userStateDraft.userDetails.phoneNumber = dataFromToken.phone_number;
          });
        })
        .catch(function (_error: AxiosError) {
          navigate("/login", { replace: true });
          setUserState((userStateDraft) => {
            userStateDraft.loggedIn = false;
            userStateDraft.userType = "";
            userStateDraft.userDetails.firstName = "";
            userStateDraft.userDetails.lastName = "";
            userStateDraft.userDetails.email = "";
            userStateDraft.userDetails.phoneNumber = "";
            userStateDraft.notifications = [];
          });
        });
    }
  }, [getTokens().IdToken]);

  useEffect(() => {
    const getNotifications = () => {
      const config = {
        headers: { Authorization: "Bearer " + getTokens().AccessToken },
      };
      const targetUrl = serviceUrl + "notification/";
      if (userState.loggedIn) {
        axios
          .get(targetUrl, config)
          .then(function (response: AxiosResponse) {
            if (
              response.data.length !== undefined ||
              response.data.length !== 0
            ) {
              setUserState((userStateDraft) => {
                userStateDraft.notifications = response.data;
              });
            }
            timerId = setTimeout(getNotifications, 30000);
          })
          .catch((error) => {
            console.error(error);
            clearTimeout(timerId);
          });
      }
    };
    let timerId = setTimeout(getNotifications, 10000);
    return () => {
      clearTimeout(timerId);
    };
  }, [userState.loggedIn]);

  return (
      <ConfigProvider
          theme={{
            "token": {
            "colorPrimary": "#304155",
            "colorInfo": "#304155"
          }
          }}
      >
        <UserContext.Provider
            value={{
              userState, setUserState
            }}
        >
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/createauction" element={<CreateAuction />} />
              <Route path="/auctions" element={<Auctions />} />
              <Route path="/myauctions" element={<MyAuctions />} />
              <Route path="/viewauction/:auctionId" element={<ViewAuction />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/edit" element={<ProfileForm />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/profile/change_password" element={<ChangePasswordForm />} />
            </Routes>
          </Layout>
        </UserContext.Provider>
      </ConfigProvider>
  );
}

export default App;
