import { useContext } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  Form,
  Button,
  Col,
  Row,
  Typography,
  message,
  Input,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import { serviceUrl } from "../../../AppConfig";
import UserContext from "../../../context/AppContext";
import Password from "antd/es/input/Password";

const { Title, Text } = Typography;

export default function RegistrationForm() {
  const { userState, setUserState } = useContext(UserContext);

  let navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  function regUserData(values: any) {
    setUserState((userStateDraft) => {
      userStateDraft.isLoading = true;
    });
    const targetUrl = serviceUrl + "registration/";
    messageApi.open({
      key,
      type: "loading",
      content: "Registration - initiated",
      duration: 2,
    });
    message.loading("Registration - initiated", 2, () => {
      axios
        .post(targetUrl, values)
        .then(function (_response: AxiosResponse) {
          messageApi.open({
            key,
            type: "success",
            content: "Registration - successful",
            duration: 2,
            onClose() {
              setUserState((userStateDraft) => {
                userStateDraft.isLoading = false;
              });
              navigate("/login");
            },
          });
        })
        .catch(function (_error: AxiosError) {
          messageApi.open({
            key,
            type: "error",
            content: "Registration - failled",
            duration: 2,
          });
          setUserState((userStateDraft) => {
            userStateDraft.isLoading = false;
          });
        });
    });
  }

  const onFinish = (values: any) => {
    console.log("Success:", values);
    regUserData(values);
  };

  return (
    <>
      {contextHolder}
      <Row>
        <Col xs={24} xl={8}></Col>
        <Col xs={24} xl={8}>
          <Form
            name="registration"
            layout="vertical"
            style={{ maxWidth: "420px", margin: "0 auto" }}
            onKeyUp={(event) => (event.key === "Enter" ? onFinish : "")}
            onFinish={regUserData}
            initialValues={{ remember: true }}
          >
            <Title level={3}>Registration</Title>
            <Form.Item
              label={<Text>Select user type</Text>}
              name="userType"
              rules={[
                {
                  required: true,
                  message: "Please select a user type",
                },
              ]}
            >
              <Select
                placeholder="Select type"
                options={[
                  {
                    value: "applicant",
                    label: "Applicant",
                  },
                  {
                    value: "bidder",
                    label: "Bidder",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label={<Text>Enter first name</Text>}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<Text>Enter last name</Text>}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please enter your last name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<Text>Enter phone number</Text>}
              name="phoneNumber"
              rules={[
                {
                  transform: v => "a",

                },
                {
                  validator: (_rule, value) => {
                    if (/^\+\d+$/.test(value)) {
                      return Promise.resolve(value);
                    } else {
                      return Promise.reject(
                        "Please enter a valid phone number, egz:. +37012345678"
                      );
                    }
                  },
                },
                {
                  required: true,
                  message: "Please enter your phone number",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={<Text>Enter password</Text>}
              name="password"
              rules={[
                {
                  type: "string",
                  min: 12,
                  message: "Password has to be at least 12 symbols",
                },
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{12,}$/,
                  message:
                    "Password requires lowercase, uppercase, and number.",
                },
              ]}
            >
              <Password />
            </Form.Item>
            <Form.Item
              label={<Text>Re-enter password</Text>}
              name="conPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Password />
            </Form.Item>
            <Button
              type="primary"
              loading={userState.isLoading}
              htmlType="submit"
            >
              Register
            </Button>
          </Form>
        </Col>
        <Col xs={24} xl={8}></Col>
      </Row>
    </>
  );
}
