import { fieldIsRequired, FormDataTypes, InitialDataType } from "../../../../../pages/CreateAuction";
import { Button, Checkbox, Divider, Form, Input, Radio, Typography } from "antd";

const { Text } = Typography;

export default function AssistanceInformation(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      auctionData.transportType = values.assistanceType ? "ambulance" : "any";
      auctionData.assistanceType = values.assitanceType
        ? values.assitanceType
        : "nonMedicalPerson";
      auctionData.specialFacilitiesNeeded = values.specialFacilitiesNeeded
        ? true
        : false;
      auctionData.otherSpecialFacilitiesNeeded = values.otherSpecialFacilitiesNeeded;
      auctionData.medicationNeededComment = values.medicationNeededComment;
      auctionData.medicationProvidedByDoctor = values.medicationProvidedByDoctor
        ? true
        : false;
      auctionData.lifeSupportFacilitiesDescription =
        values.lifeSupportFacilitiesDescription;
      auctionData.oxygenFacilitiesDescription = values.oxygenFacilitiesDescription;

      console.log("values", values);
      console.log("stored values ", props.filledData);
      console.log("repType", repType);
    })
  }

  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <>
      <Form
        name="assistaceInformation"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{
          transportType: data.transportType || false,
          assistanceType: data.assistanceType || "nonMedicalPerson",
          specialFacilitiesNeeded: data.specialFacilitiesNeeded || false,
          medicationNeeded: data.medicationNeeded || false,
          otherSpecialFacilitiesNeeded: data.otherSpecialFacilitiesNeeded,
          medicationNeededComment: data.medicationNeededComment,
          medicationProvidedByDoctor: data.medicationProvidedByDoctor,
          lifeSupportFacilitiesDescription: data.lifeSupportFacilitiesDescription,
          oxygenFacilitiesDescription: data.oxygenFacilitiesDescription
        }}
      >
        <Text>Kind of Assistant needed</Text>
        <Divider />
        {(repType === "aircraft" || repType === "regularFlight") && (
          <Form.Item
            label={<Text>Type of transport to and from aircraft</Text>}
            name="transportType"
          >
            <Radio.Group
              defaultValue={false}
              onChange={(event) => {
                props.setFilledData((draftFilledData: any) => {
                  const auctionData = draftFilledData.auction_details[repType];
                  auctionData.transportType = event.target.value;
                })
              }}
              options={[
                {
                  label: "Any available",
                  value: false,
                },
                {
                  label: "Ambulance",
                  value: true,
                },
              ]}
            />
          </Form.Item>
        )}

        <Form.Item
          label={<Text>Kind of assistance</Text>}
          name="assistanceType"
        >
          <Radio.Group
            defaultValue={"nonMedicalPerson"}
            onChange={(event) => {
                props.setFilledData((draftFilledData: any) => {
                  const auctionData = draftFilledData.auction_details[repType];
                  auctionData.assistanceType = event.target.value
                })
            }}
            options={[
              {
                label: "Non medical person, or family member",
                value: "nonMedicalPerson",
              },
              {
                label: "Nurse or paramedic",
                value: "medicalPerson",
              },
              {
                label: "Doctor",
                value: "doctor",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label={<Text>Kind of assistance</Text>}
          name="specialFacilitiesNeeded"
        >
          <Radio.Group
            defaultValue={false}
            onChange={(event) => {
                props.setFilledData((draftFilledData: any) => {
                  const auctionData = draftFilledData.auction_details[repType];
                  auctionData.specialFacilitiesNeeded = event.target.value
                })
            }}
            options={[
              {
                label: "No need for special facilities",
                value: false,
              },
              {
                label: "Necessary facilities needed for the transportation",
                value: true,
              },
            ]}
          />
        </Form.Item>
        {data.specialFacilitiesNeeded && (
          <>
            <div style={{ marginLeft: "20px" }}>
              <Form.Item
                label={<Text>Orthopedic facilities</Text>}
                name="specialFacilitiesNeededList"
              >
                <Checkbox.Group
                  name="specialFacilitiesNeededList"
                  value={Array.isArray(data.specialFacilitiesNeededList) ? data.specialFacilitiesNeededList : []}
                  onChange={(checkedValues) =>
                    props.setFilledData((draftFilledData: any) => {
                      const auctionData = draftFilledData.auction_details[repType];
                      auctionData.specialFacilitiesNeededList = checkedValues;
                    })
                  }
                  options={[
                    { label: "Crutches", value: "crutches" },
                    { label: "Wheelchair", value: "wheelchair" },
                    { label: "Corset / stabilizer", value: "stabilizer" },
                    { label: "Stretcher", value: "stretcher" },
                    { label: "Vacuum Stretcher", value: "vacuum" },
                    { label: "Other", value: "other" },
                  ]}
                />
                {(data.specialFacilitiesNeededList && data.specialFacilitiesNeededList.includes("other")) && (
                  <Form.Item
                    label={<Text>Describe orthopedic facility</Text>}
                    name="otherSpecialFacilitiesNeeded"
                    rules={fieldIsRequired}
                  >
                    <Input allowClear />
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item>
                <Checkbox.Group
                  name="medicationNeeded"
                  value={Array.isArray(data.medicationNeeded) ? data.medicationNeeded : []}
                  onChange={(checkedValues) => {
                    props.setFilledData((draftFilledData: any) => {
                      const auctionData = draftFilledData.auction_details[repType];
                      auctionData.medicationNeeded = checkedValues
                    })
                  }}
                  options={[{ label: "Medication needed", value: "needed" }]}
                />
              </Form.Item>
              {(data.medicationNeeded && data.medicationNeeded.includes("needed")) && (
                <>
                  <Form.Item
                    label={<Text>Please indicate the medication</Text>}
                    name="medicationNeededComment"
                    rules={fieldIsRequired}
                  >
                    <Input allowClear />
                  </Form.Item>
                  <Form.Item
                    label={
                      <Text>
                        Will doctor provide this medication for the transport?
                      </Text>
                    }
                    name="medicationProvidedByDoctor"
                  >
                    <Radio.Group
                      defaultValue={false}
                      onChange={(event) => {
                          props.setFilledData((draftFilledData: any) => {
                            const auctionData = draftFilledData.auction_details[repType];
                            auctionData.medicationProvidedByDoctor = event.target.value
                          })
                      }}
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                label={<Text>Other</Text>}
                name="otherFacilities"
              >
                <Checkbox.Group
                  name="otherFacilities"
                  value={Array.isArray(data.otherFacilities) ? data.otherFacilities : []}
                  onChange={(checkedValues) =>
                    props.setFilledData((draftFilledData: any) => {
                      const auctionData = draftFilledData.auction_details[repType];
                      auctionData.otherFacilities = checkedValues;
                    })
                  }
                  options={[
                    {
                      label: "Life supporting facilities",
                      value: "lifeSupport",
                    },
                    { label: "Oxygen", value: "oxygen" },
                  ]}
                />
                {(data.otherFacilities && data.otherFacilities.includes("lifeSupport")) && (
                  <Form.Item
                    label={<Text>Describe needed facilities</Text>}
                    name="lifeSupportFacilitiesDescription"
                    rules={fieldIsRequired}
                  >
                    <Input allowClear />
                  </Form.Item>
                )}
                {(data.otherFacilities && data.otherFacilities.includes("oxygen")) && (
                  <Form.Item
                    label={
                      <Text>How many oxygen needed for transportation</Text>
                    }
                    name="oxygenFacilitiesDescription"
                    rules={fieldIsRequired}
                  >
                    <Input allowClear />
                  </Form.Item>
                )}
              </Form.Item>
            </div>
          </>
        )}
        <div>
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
