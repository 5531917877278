import { useEffect, useMemo, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { serviceUrl } from "../AppConfig";
import { Link, useNavigate } from "react-router-dom";
import { getTokens, removeTokens } from "../context/AppContext";
import AuctionsTable from "../components/AuctionsTable";
import { auctionItemData } from "../context/RepDataTypes";
import { useImmer } from "use-immer";
import Title from "antd/es/typography/Title";

// interface Auction {
//   id: number;
//   name: string;
//   startTime: string;
//   endTime: string;
// }

export default function MyAuctions() {
  // Declare state variables to store the auctions data and a loading indicator
  const [auctions, setAuctions] = useImmer<auctionItemData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  // Use the useMemo hook to memoize the auctions data
  useMemo(() => {
    const targetUrl = serviceUrl + "receiver/my_auctions";
    const authorizationHeader = {
      Authorization: "Bearer " + getTokens().AccessToken,
    };
    // Set the loading indicator to true
    setIsLoading(true);
    // Make the request to get the auctions data
    axios
      .get<auctionItemData[]>(targetUrl, {
        headers: authorizationHeader,
        params: {
          userType: getTokens().userType,
        },
      })
      .then((response: AxiosResponse<auctionItemData[]>) => {
        // Update the state with the response data and set the loading indicator to false
        setAuctions(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle the error and set the loading indicator to false
        console.error(error);
        setIsLoading(false);
        if (error.response?.status === 401) {
          removeTokens();
        }
        navigate("/login", { replace: true });
      });
  }, []);

  // Render the component
  return (
    <>
      <Title
          level={4}
          style={{
            marginBottom: "48px",
          }}
      >
        My auctions
      </Title>
      <AuctionsTable
        auctionItems={auctions}
        auctionUpdate={setAuctions}
        loadingStatus={isLoading}
      />
    </>
  );
}
