import { Button, Divider, Form, Input, Typography } from "antd";
import {
  commonLocationFields, fieldIsRequired, FormDataTypes, InitialDataType,
} from "../../../../../pages/CreateAuction";

const { Text } = Typography;

export default function LocationDestination(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      if (!(auctionData.departureFrom)) {
        auctionData.departureFrom = {}
      }
      const departureFrom = auctionData.departureFrom;
      if (!(auctionData.departureTo)) {
        auctionData.departureTo = {}
      }
      const departureTo = auctionData?.departureTo;

      departureFrom.country = values.fromCountry;
      departureFrom.city = values.fromCity;
      departureFrom.fullAdress = values.fromFullAdress;
      departureFrom.contacts = values.fromContacts;
      departureTo.country = values.toCountry;
      departureTo.city = values.toCity;
      departureTo.fullAdress = values.toFullAdress;
      departureTo.contacts = values.toContacts;
    });

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }

  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <>
      <Form
        name="patientMedicalDetails"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{
          fromCountry: data.departureFrom.country,
          fromCity: data.departureFrom.city,
          fromFullAdress: data.departureFrom.fullAdress,
          fromContacts: data.departureFrom.contacts,
          toCountry: data.departureTo.country,
          toCity: data.departureTo.city,
          toFullAdress: data.departureTo.fullAdress,
          toContacts: data.departureTo.contacts,
        }}
      >
        <Text>Repatriation details (places)</Text>
        <Divider />
        <Text>Location of the deceased (morgue)</Text>
        {commonLocationFields("from")}
        <Form.Item
          label={<Text>Contacts</Text>}
          name="fromContacts"
          rules={fieldIsRequired}
        >
          <Input.TextArea rows={1} allowClear />
        </Form.Item>
        <Text>Destination address</Text>
        {commonLocationFields("to")}
        <Form.Item
          label={<Text>Contacts</Text>}
          name="toContacts"
          rules={fieldIsRequired}
        >
          <Input.TextArea rows={1} allowClear />
        </Form.Item>
        <div>
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
