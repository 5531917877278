import { Breadcrumb, Button, Col, Form, message, Row, Typography } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { serviceUrl } from "../../../AppConfig";
import UserContext, { getTokens } from "../../../context/AppContext";
import PasswordField from "./formElements/PasswordField";

const { Title, Text } = Typography;

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const [changePassword, setChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { userState, setUserState } = useContext(UserContext);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  useEffect(() => {
    console.log("changePassword", changePassword);
  }, [changePassword]);
  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  function handlePasswordChange(value: string, name: string) {
    setChangePassword((prevChangePassword) => ({
      ...prevChangePassword,
      [name]: value,
    }));
  }

  function handleSave() {
    let oldPasswordError = "";
    let newPasswordError = "";
    let conPasswordError = "";

    if (
      changePassword.oldPassword.length < 12 ||
      !/\d/.test(changePassword.oldPassword) ||
      !/[a-z]/.test(changePassword.oldPassword) ||
      !/[A-Z]/.test(changePassword.oldPassword)
    ) {
      oldPasswordError = "Unmet password requirements";
    } else {
      oldPasswordError = "";
    }

    if (changePassword.newPassword.length < 12) {
      newPasswordError = "New password must be at least 12 characters long";
    } else if (!/\d/.test(changePassword.newPassword)) {
      newPasswordError = "New password must contain at least one number";
    } else if (!/[a-z]/.test(changePassword.newPassword)) {
      newPasswordError =
        "New password must contain at least one lowercase letter";
    } else if (!/[A-Z]/.test(changePassword.newPassword)) {
      newPasswordError =
        "New password must contain at least one uppercase letter";
    } else {
      newPasswordError = "";
    }
    if (changePassword.confirmPassword !== changePassword.newPassword) {
      conPasswordError = "Passwords do not match";
    } else {
      conPasswordError = "";
    }
    setErrors(() => ({
      oldPassword: oldPasswordError,
      newPassword: newPasswordError,
      confirmPassword: conPasswordError,
    }));
    if (
      oldPasswordError === "" &&
      newPasswordError === "" &&
      conPasswordError === ""
    ) {
      console.log("Password checks - passed");
      initiateChange();
    }
  }

  function initiateChange() {
    setUserState((userStateDraft) => {
      userStateDraft.isLoading = true;
    });
    messageApi.open({
      key,
      type: "loading",
      content: "Initiating...",
      duration: 10,
    });
    const config = {
      url: "change_password/",
      method: "post",
      baseURL: serviceUrl,
      headers: { Authorization: "Bearer " + getTokens().AccessToken },
      data: {
        oldPassword: changePassword.oldPassword,
        newPassword: changePassword.newPassword,
      },
    };

    axios
      .request(config)
      .then(function (_response: AxiosResponse) {
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "success",
          content: "Changed",
          duration: 1,
          onClose() {
            navigate("/profile");
          },
        });
      })
      .catch(function (_error: AxiosError) {
        setUserState((userStateDraft) => {
          userStateDraft.isLoading = false;
        });
        messageApi.open({
          key,
          type: "error",
          content: "Error",
          duration: 4,
        });
      });
  }

  return (
    <>
      {contextHolder}
      <Title
        level={3}
        style={{
          marginBottom: "24px",
        }}
      >
        Change Password
      </Title>

      <Breadcrumb
        style={{
          marginBottom: "40px",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/profile">Profile</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="#">Change password</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xs={24} xl={8}></Col>
        <Col xs={24} xl={8}>
          <Form
            layout="vertical"
            style={{ maxWidth: "420px", margin: "0 auto" }}
            onKeyUp={(event) => (event.key === "Enter" ? handleSave() : "")}
          >
            <Form.Item
              label={<Text>Enter old password</Text>}
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your old password",
                },
              ]}
              validateStatus={errors.oldPassword !== "" ? "error" : ""}
              help={errors.oldPassword}
            >
              <PasswordField
                placeholder=""
                name="oldPassword"
                handleChange={handlePasswordChange}
              />
            </Form.Item>
            <Form.Item
              label={<Text>Enter new password</Text>}
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your old password",
                },
              ]}
              validateStatus={errors.newPassword !== "" ? "error" : ""}
              help={errors.newPassword}
            >
              <PasswordField
                placeholder=""
                name="newPassword"
                handleChange={handlePasswordChange}
              />
            </Form.Item>
            <Form.Item
              label={<Text>Re-enter password</Text>}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your old password",
                },
              ]}
              validateStatus={errors.confirmPassword !== "" ? "error" : ""}
              help={errors.confirmPassword}
            >
              <PasswordField
                placeholder=""
                name="confirmPassword"
                handleChange={handlePasswordChange}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                loading={userState.isLoading}
                danger
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                type="default"
                onClick={() =>
                  navigate("/profile", {
                    replace: true,
                  })
                }
                style={{
                  marginLeft: "12px",
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} xl={8}></Col>
      </Row>
    </>
  );
}
