import { Button, Divider, Form, Input, Radio, Typography } from "antd";
import { fieldIsRequired, FormDataTypes, InitialDataType } from "../../../../../pages/CreateAuction";

const { Text } = Typography;

export default function AditionalDetails(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      if (!(auctionData.otherInstitution)) {
        auctionData.otherInstitution = {}
      };
      auctionData.otherInstitution.name = values.name;
      auctionData.specialRequirements = values.specialRequirements;
    });

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }

  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <>
      <Form
        name="remainsAditionalDetails"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{
          involved: data.otherInstitution?.involved,
          name: data.otherInstitution?.name,
          specialRequirements: data.specialRequirements,
        }}
      >
        <Text>Additional details on repatriation</Text>
        <Divider />
        {/* otherInstitution */}
        <Form.Item
          label={<Text>Other institution involved</Text>}
          name="involved">
          <Radio.Group
            defaultValue={false}
            onChange={(event) => {
              props.setFilledData((draftFilledData: any) => {
                const auctionData = draftFilledData.auction_details[repType];
                if (!(auctionData.otherInstitution)) {
                  auctionData.otherInstitution = {}
                }
                auctionData.otherInstitution.involved = event.target.value;
              })
            }}
            options={[
              {
                label: "Yes",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ]}
          />
        </Form.Item>
        {data.otherInstitution && data.otherInstitution.involved &&
          <>
            <Form.Item
              label={<Text>Indentify involved institution</Text>}
              name="name"
              rules={fieldIsRequired}
            >
              <Input allowClear />
            </Form.Item>
            <Divider />
          </>
        }
        <Form.Item
          label={<Text>Special requirements</Text>}
          name="specialRequirements"
        // rules={fieldIsRequired}
        >
          <Input allowClear />
        </Form.Item>
        <div>
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
