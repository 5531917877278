export const userTypeOptions = [
  {
    label: "Bidder",
    value: "bidder",
  },
  {
    label: "Applicant",
    value: "applicant",
  },
];
