import {List, Typography} from "antd";
import React, { useContext } from "react";
import UserContext from "../context/AppContext";

export interface NotifMessage {
  notificationId: string;
  read_at?: null | string;
  created_at: string;
  title: string;
  content: string;
  createdAt: string;
  type: string;
}

const {Text } = Typography;

export default function Notifications() {
  const userContext = useContext(UserContext);
  const data: NotifMessage[] = userContext.userState.notifications;
    console.log(userContext.userState.notifications);

  return (
    <>
        <List
            style={{
                marginBottom: "24px",
            }}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text>
                                {item.title} <br/>
                                    <Text
                                        type={"secondary"}
                                        style={{
                                            fontWeight: "normal",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {item.created_at}
                                    </Text>
                                <br/>

                            </Text>
                        }

                        description={<Text>{item.content.replace(/(<([^>]+)>)/ig, '')}</Text>}
                    />
                </List.Item>
              )}
          />
    </>
  );
}