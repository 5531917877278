import React, { useEffect, useMemo, useState } from "react";
import {Breadcrumb, Form, Input, Steps, Typography} from "antd";
import GeneralInformation from "../components/ui/form/CreateAuction/GeneralInformation";
import { useImmer } from "use-immer";
import getCommonOptions from "../components/commonOptions/getSelectionOptions";
import AssistanceInformation from "../components/ui/form/CreateAuction/patient/AssistanceInformation";
import ApplicantContactDetails from "../components/ui/form/CreateAuction/ApplicantContactDetails";
import dayjs from "dayjs";
import DestinationInformation from "../components/ui/form/CreateAuction/patient/DestinationInformation";
import PatientGeneralInformation from "../components/ui/form/CreateAuction/patient/PatientGeneralInformation";
import PatientMedicalDetails from "../components/ui/form/CreateAuction/patient/PatientMedicalDetails";
import PatientOtherMedicalIssues from "../components/ui/form/CreateAuction/patient/PatientOtherMedicalIssues";
import DeathDetails from "../components/ui/form/CreateAuction/remains/DeathDetails";
import LocationDestination from "../components/ui/form/CreateAuction/remains/LocationDestination";
import RemainsDetails from "../components/ui/form/CreateAuction/remains/RemainsDetails";
import FamilyContact from "../components/ui/form/CreateAuction/remains/FamilyContact";
import AditionalDetails from "../components/ui/form/CreateAuction/remains/AditionalDetails";
import Title from "antd/es/typography/Title";
import {Link} from "react-router-dom";

const { Text } = Typography;

export interface RepTypeOptionVer2 {
  key: string;
  label: string;
  value: string;
  subtypes?: [
    {
      key: string;
      label: string;
      value: string;
    }
  ];
}

export interface InitialDataType {
  name: string;
  status: string;
  auction_details: AuctionDetails
  endingTime: string;
  bidIncrement: number;
}

export interface AuctionDetails {
    repatriationType: string;
    repatriationSubtype: string;
    [key: string]: FormDataTypes | string;
}
export interface FormDataTypes {
  departureFrom?: any;
  departureTo?: any;
  applicantContacts?: any;
  familyMember?: any;
  soonestDateOfTransport: string
  [key: string]: any;
}

export const fieldIsRequired = [
  {
    required: true,
    message: "The field is required",
  },
];

export function disabledFutureDates(current: any) {
  return current && current > dayjs().endOf("day");
}

export function disabledPastDates(current: any) {
  return current && current < dayjs().endOf("day");
}

export function commonLocationFields(prefix: string) {
  return (
    <>
      <Form.Item
        label={<Text>Country</Text>}
        name={`${prefix}Country`}
        rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>

      <Form.Item
        label={<Text>City</Text>}
        name={`${prefix}City`}
        rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>

      <Form.Item
        label={<Text>Full address</Text>}
        name={`${prefix}FullAdress`}
        rules={fieldIsRequired}
      >
        <Input.TextArea rows={1} allowClear />
      </Form.Item>
    </>
  );
}

export default function CreateAuction() {
  const [filledData, setFilledData] = useImmer<any>({ auction_details: {} });
  useEffect(() => {
    console.log("useEffect filledData changed in CreateAuction", filledData);
  }, [filledData]);

  const [currentStep, setCurrentStep] = useState(0);

  const [repatriationTypesVer2, setRepatriationTypesVer2] = useImmer<
    RepTypeOptionVer2[]
  >([]);
  useMemo(() => {
    getCommonOptions("REPATRIATION_TYPE_VER2").then((repatriationOption) => {
      setRepatriationTypesVer2(repatriationOption);
    });
    console.log("REPATRIATION_TYPE_VER2", repatriationTypesVer2);
  }, [setRepatriationTypesVer2]);
  const repType = filledData.auction_details?.repatriationType
  console.log("repType", repType)
  const commonProps = {
    currentStep,
    setCurrentStep,
    filledData,
    setFilledData,
  };

  const patientSteps = [
    {
      title: "",
      content: <DestinationInformation {...commonProps} />,
    },
    {
      title: "",
      content: <AssistanceInformation {...commonProps} />,
    },
    {
      title: "",
      content: <PatientGeneralInformation {...commonProps} />,
    },
    {
      title: "",
      content: <PatientMedicalDetails {...commonProps} />,
    },
    {
      title: "",
      content: <PatientOtherMedicalIssues {...commonProps} />,
    },
  ]

  const remainsSteps = [
    {
      title: "",
      content: <DeathDetails {...commonProps} />,
    },
    {
      title: "",
      content: <LocationDestination {...commonProps} />,
    },
    {
      title: "",
      content: <RemainsDetails {...commonProps} />,
    },
    {
      title: "",
      content: <FamilyContact {...commonProps} />,
    },
    {
      title: "",
      content: <AditionalDetails {...commonProps} />,
    },
  ]

  const steps = [
    {
      title: "",
      content: (
        <GeneralInformation repTypes={repatriationTypesVer2} {...commonProps} />
      ),
    },
    ...(repType && repType === "remains" ? remainsSteps : patientSteps),
    {
      title: "",
      content: <ApplicantContactDetails {...commonProps} />,
    },
  ];

  const items = steps.map((item, index) => ({ key: index, title: item.title }));

  return (
    <>
      <Title
          level={3}
          style={{
            marginBottom: '24px'
          }}
      >
        Create auction
      </Title>

      <Breadcrumb
          style={{
            marginBottom: '40px'
          }}
      >
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="#">Create auction</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Steps
        style={{ marginTop: 56 }}
        responsive
        current={currentStep}
        items={items}
      />
      <div
        style={{
          marginTop: 24,
        }}
      >
        {steps[currentStep].content}
      </div>
    </>
  );
}
