import { ChangeEventHandler } from "react";
import { Input } from "antd";

export default function TextField(props: {
  key?: string;
  placeholder: string;
  name: string;
  handleChange: (value: string, name: string) => void;
  inputValue?: string;
  defaultValue?: string;
}) {
  function handleChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    props.handleChange(event.target.value, props.name);
  }

  const newProps = { ...props };
  if (!newProps.defaultValue) {
    delete newProps["defaultValue"];
  }

  if (newProps.defaultValue) {
    newProps.key = `${newProps.key || newProps.name}_${newProps.defaultValue}`;
  } else {
    newProps.key = `${newProps.key || newProps.name}`;
  }

  return (
    <Input
      allowClear
      type="text"
      {...newProps}
      onChange={handleChangeInput}
    ></Input>
  );
}
