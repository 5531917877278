export function showLocalizedDate(date: string) {
    const localizedDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedDate = localizedDate.toLocaleString("lt-LT", options);
    return formattedDate;
  }
  
  export function hasEnded(date: string): boolean {
    const now = Date.now();
    const endTime = new Date(date).getTime();
    return now >= endTime;
  }
  
  // month is 30 days for calendar calculations use date-fns or moment.js libraries
  export function getTimeDifference(startDate: Date, endDate: Date): string {
    const diff = endDate.getTime() - startDate.getTime();
    if (diff < 0) {
      return "ENDED"
    }
    const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24)) % 30;
    const hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
    const minutes = Math.floor(diff / (1000 * 60)) % 60;
    const timeComponents = [];
    if (months > 0) {
      timeComponents.push(`${months} month${months > 1 ? "s" : ""}`);
    }
    if (days > 0) {
      timeComponents.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (hours > 0) {
      timeComponents.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    }
    if (minutes > 0) {
      timeComponents.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    }
    return timeComponents.join(" ");
  }