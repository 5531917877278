import { Button, Form, Radio, Select, Space, Typography } from "antd";
import Input from "antd/es/input/Input";
import { first } from "lodash";
import { useEffect } from "react";
import {
  InitialDataType,
  RepTypeOptionVer2,
} from "../../../../pages/CreateAuction";
import Title from "antd/es/typography/Title";

const { Text } = Typography;
const { useForm } = Form;

export default function GeneralInformation(props: {
  repTypes: RepTypeOptionVer2[];
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const initialSelectedType = first(props.repTypes.filter(
    repType => repType.value === props.filledData.auction_details?.repatriationType)
  );
  const initialSelectedSubtype = first(initialSelectedType?.subtypes?.filter(
    subtype => subtype.value === props.filledData.auction_details?.repatriationSubtype
  ));

  const [form] = useForm();

  useEffect(() => {
    form.resetFields(["repSubtype"]);
  }, [form, initialSelectedType]);

  function next(values: any) {

    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionDetails = draftFilledData.auction_details;

      draftFilledData.name = values.name;
      auctionDetails.repatriationType = values.repType;
      auctionDetails.repatriationSubtype = values.repSubtype;
      if (!(auctionDetails[values.repType])) {
        draftFilledData.auction_details[values.repType] = {
          departureFrom: {},
          departureTo: {},
        }
      }
    });
    console.log("values", values)
  }

  function handleRepTypeChange(value: string, option: any) {
    props.setFilledData((draftFilledData: any) => {
      const auctionDetails = draftFilledData.auction_details;

      auctionDetails.repatriationType = value;
      auctionDetails.repatriationSubtype = option?.subtypes ? option?.subtypes[0].value : "";
    });
  }

  return (
    <>
      <Form
        form={form}
        name="generalInformation"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{
          name: props.filledData.name,
          repType: props.filledData.auction_details?.repatriationType,
          repSubtype: initialSelectedSubtype?.value,
        }}
      >
        <Title
            level={4}
            style={{
              margin: "48px 0 24px 0",
            }}
        >General application information</Title>
        <Form.Item
          label={<Text>Application name</Text>}
          name="name"
          rules={[
            {
              required: true,
              message: "Provide application name",
            },
          ]}
        >
          <Input
              placeholder="Application name"
              allowClear
          />
        </Form.Item>
        <Form.Item
          label={<Text>Select user type</Text>}
          name="repType"
          rules={[
            {
              required: true,
              message: "Please select a user type",
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Select type"
            options={props.repTypes}
            onChange={handleRepTypeChange}
          />
        </Form.Item>
        {initialSelectedType && initialSelectedType.subtypes && (
          <Space direction="vertical">
            <Form.Item label={<Text>Subtypes</Text>} name="repSubtype">
              <Radio.Group>
                {initialSelectedType?.subtypes?.map(subtype => {
                  return <Radio key={subtype.key} value={subtype.value}>{subtype.label}</Radio>
                })}
              </Radio.Group>
            </Form.Item>
          </Space>
        )}
        <div>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
