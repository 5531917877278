import { Button, Divider, Form, Input, Typography } from "antd";
import { FormDataTypes, InitialDataType } from "../../../../../pages/CreateAuction";

const { Text } = Typography;

export default function FamilyContact(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      if (!(auctionData.familyMember)) {
        auctionData.familyMember = {}
      }
      const familyData = auctionData.familyMember;

      familyData.relation = values.relation;
      familyData.name = values.name;
      familyData.surname = values.surname;
      familyData.email = values.email;
      familyData.phoneNumber = values.phoneNumber;
    });

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }

  const data = props.filledData.auction_details[repType] as FormDataTypes

  return (
    <>
      <Form
        name="patientMedicalDetails"
        layout="vertical"
        style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
        onFinish={next}
        initialValues={{
          relation: data.familyMember?.relation,
          name: data.familyMember?.name,
          surname: data.familyMember?.surname,
          email: data.familyMember?.email,
          phoneNumber: data.familyMember?.phoneNumber,
        }}
      >
        <Text>Family member contact</Text>
        <Divider />
        <Form.Item
          label={<Text>Relation</Text>}
          name="relation"
        // rules={fieldIsRequired}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label={<Text>Name</Text>}
          name="name"
        // rules={fieldIsRequired}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label={<Text>Surname</Text>}
          name="surname"
        // rules={fieldIsRequired}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="email"
          label={<Text>Email</Text>}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label={<Text>Enter phone number</Text>}
          name="phoneNumber"
          rules={[
            {
              transform: (v) => "a",
            },
            {
              validator: (_rule, value) => {
                if (/^\+\d+$/.test(value)) {
                  return Promise.resolve(value);
                } else {
                  return Promise.reject(
                    "Please enter a valid phone number, egz:. +37012345678"
                  );
                }
              },
            },
            {
              required: true,
              message: "Please enter your phone number",
            },
          ]}
        >
          <Input style={{ width: "100%" }} allowClear />
        </Form.Item>

        <div>
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
