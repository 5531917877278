import { InputNumber } from 'antd';

export default function NumberField(props: {
  placeholder?: string;
  name: string;
  handleChange: (value: number, name: string) => void;
  inputValue: any;
}) {

  function handleChangeInput (value: number | null) {
    if (value !== null) props.handleChange(value, props.name);
  }

  return (
    <InputNumber
      style={{ width: '100%' }}
      placeholder={props.placeholder}
      name={props.name}
      onChange={handleChangeInput}
      value={props.inputValue}
    >
    </InputNumber>
  );
}
