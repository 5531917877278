import { Button, Divider, Form, Input, Radio, Typography } from "antd";
import { FormDataTypes, InitialDataType } from "../../../../../pages/CreateAuction";

const { Text } = Typography;

export default function PatientOtherMedicalIssues(props: {
  currentStep: number;
  setCurrentStep: any;
  filledData: InitialDataType;
  setFilledData: any;
}) {
  const repType = props.filledData.auction_details.repatriationType;

  const prev = () => {
    props.setCurrentStep(props.currentStep - 1);
  };

  function next(values: any) {
    props.setCurrentStep(props.currentStep + 1);
    props.setFilledData((draftFilledData: any) => {
      const auctionData = draftFilledData.auction_details[repType];
      auctionData.otherMedicalProblems = values.otherMedicalProblems;
      auctionData.medicationRequiredForTransport = values.medicationRequiredForTransport;
    })

    console.log("values", values);
    console.log("stored values ", props.filledData);
    console.log("repType", repType);
  }

function YesNoOptions(label: string, name: string) {
  return (
    <Form.Item label={<Text>{label}</Text>} name={name}>
      <Radio.Group
        defaultValue={false}
        onChange={(event) => {
            props.setFilledData((draftFilledData: any) => {
              const auctionData = draftFilledData.auction_details[repType];
              auctionData[name] = event.target.value;
            })
        }}
        options={[
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ]}
      />
    </Form.Item>
  );
}

const data = props.filledData.auction_details[repType] as FormDataTypes

return (
  <>
    <Form
      name="patientMedicalDetails"
      layout="vertical"
      style={{ maxWidth: "420px", minWidth: "320px", margin: "0 auto" }}
      onFinish={next}
      initialValues={{ 
        otherMedicalProblems: data.otherMedicalProblems,
        medicationRequiredForTransport: data.medicationRequiredForTransport,
        peripheralVeinCatheterization: data.peripheralVeinCatheterization,
        centralVeinCatheter: data.centralVeinCatheter,
        urinalCatheter: data.urinalCatheter,
        stoolIncontinence: data.stoolIncontinence,
        nasoGastricTube: data.nasoGastricTube,
        tracheostomy: data.tracheostomy,
        pulmonaryVentilation: data.pulmonaryVentilation,
        suctioningOfFluids: data.suctioningOfFluids,
      }}
    >
      <Text>Information about Patient (Other medical problems)</Text>
      <Divider />
      <Form.Item
        label={
          <Text>
            Other medical problems (wounds, dressings, traction, drainage,
            colostomy)
          </Text>
        }
        name="otherMedicalProblems"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>

      {YesNoOptions(
        "Peripheral vein catheterization",
        "peripheralVeinCatheterization"
      )}
      {YesNoOptions("Central vein catheter", "centralVeinCatheter")}
      {YesNoOptions("Urinal catheter", "urinalCatheter")}
      {YesNoOptions("Stool incontinence", "stoolIncontinence")}
      {YesNoOptions("Naso-gastric tube", "nasoGastricTube")}
      {YesNoOptions("Tracheostomy", "tracheostomy")}
      {YesNoOptions("Pulmonary ventilation", "pulmonaryVentilation")}
      {YesNoOptions(
        "Suctioning of mucus and other fluids",
        "suctioningOfFluids"
      )}

      <Form.Item
        label={
          <Text>
            Medication required for the transport: name (active substance),
            dosage, way of application
          </Text>
        }
        name="medicationRequiredForTransport"
      //   rules={fieldIsRequired}
      >
        <Input allowClear />
      </Form.Item>

      <div>
        <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
          Previous
        </Button>
        <Button type="primary" htmlType="submit">
          Next
        </Button>
      </div>
    </Form>
  </>
);
}
